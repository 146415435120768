import routerProvider from '@refinedev/react-router-v6/legacy';
import { Button, Col, FormProps, Row, Space, Table, TableProps } from 'antd';
import { TagField, TextField } from '@refinedev/antd';

TextField;
import { EditOutlined } from '@ant-design/icons';
import { IAssessmentQuestion } from 'interfaces/assessments';
import { tagColorByStatus } from 'utils/statusColorMapping';

interface IFilter {
  statement: string;
  assessment_id: string;
}

interface AssessmentQuestionListProps {
  searchFormProps: FormProps<IFilter>;
  tableProps: TableProps<IAssessmentQuestion>;
}

const QUESTION_STATUSES_MAP: Record<IAssessmentQuestion['status'], string> = {
  active: 'Ativo',
  inactive: 'Inativo',
  nullified: 'Anulada'
};

export const AssessmentQuestionList = ({
  tableProps
}: AssessmentQuestionListProps) => {
  const { Link } = routerProvider;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="plain_statement"
              key="plain_statement"
              title="Questão"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="alternatives"
              key="alternatives"
              title="Alternativas"
              align="center"
              render={value => <TextField value={value.length} />}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              align="center"
              render={(value: IAssessmentQuestion['status']) => (
                <TagField
                  color={tagColorByStatus(value)}
                  style={{ border: '1px solid #d9d9d9' }}
                  value={QUESTION_STATUSES_MAP[value]}
                />
              )}
            />

            <Table.Column
              title="Ações"
              dataIndex="actions"
              render={(_, record: IAssessmentQuestion) => {
                return (
                  <Space>
                    <Link
                      to={`/teaching_resources/assessment_questions/edit/${record.id}`}
                    >
                      <Button icon={<EditOutlined />} size="small" />
                    </Link>
                  </Space>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};
