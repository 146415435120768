import { BonusCreate, BonusList, BonusShow } from 'pages/ecommerce/bonuses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'product_bonuses',
  label: 'Bônus',
  list: BonusList,
  create: BonusCreate,
  show: BonusShow,
  actions: ['list', 'show', 'create'],
  parentName: 'admin'
};

export default generateResources(resource);
