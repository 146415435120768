import TagTree from 'components/epimetheus/tags/TagTree';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ITag } from 'interfaces/epimetheus/tags';
import { useOne } from '@refinedev/core';

export const TagEdit = () => {
  const { id, formProps, saveButtonProps } = useForm<ITag>();

  const { data } = useOne<ITag>({
    resource: 'epimetheus/tags',
    id: id!
  });

  const tag = data?.data;

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      {tag && <TagTree tag={tag} />}
    </Edit>
  );
};
