import { IGraduateCourse } from 'interfaces/graduate_courses';
import { Table } from 'antd';
import { TextField } from '@refinedev/antd';

interface ShowTableGraduateCoursesProps {
  graduate_courses: IGraduateCourse[];
}

export const ShowTableGraduateCourses = ({
  graduate_courses
}: ShowTableGraduateCoursesProps) => {
  return (
    <Table
      dataSource={graduate_courses}
      rowKey="id"
      className="w-full"
      pagination={false}
    >
      <Table.Column
        dataIndex="name"
        key="name"
        title="Curso"
        render={value => <TextField value={value} />}
      />
    </Table>
  );
};
