import { ITenant } from 'interfaces/tenants';

type TEnv = 'development' | 'staging' | 'production';

interface ITenantList {
  development: ITenant[];
  staging: ITenant[];
  production: ITenant[];
}

const env: TEnv = (process.env.REACT_APP_TENANT_ENV as TEnv) || 'development';

// desenvApiUrl = deixar vazio antes de jogar pra PROD
const desenvApiUrl = '';
const stgApiUrl = 'https://atlas.opx-hmg.grupoq.io';

const tenantList: ITenantList = {
  development: [
    {
      name: 'Development',
      domains: ['localhost'],
      apiUrl: desenvApiUrl || stgApiUrl
    }
  ],
  staging: [
    {
      name: 'Qcarreiras - Staging',
      domains: [
        'admin.atlas.qcarreiras-hmg.com',
        /.*\.atlas-admin\.pages\.dev/
      ],
      apiUrl: stgApiUrl,
      lmsUrl: 'https://ensino.damasio.com.br',
      googleId:
        '410228309266-ss6jgc4538h9prvh2f7u1v53unfs0vdf.apps.googleusercontent.com'
    },
    {
      name: 'Damásio - Staging',
      domains: ['admin-damasio.opx-hmg.grupoq.io'],
      apiUrl: 'https://atlas.opx-hmg.grupoq.io',
      logoUrl: 'https://assets.olimpo.grupoq.io/assets/themis/dark-logo.svg',
      disabledFeatures: ['faq_coordinators']
    }
  ],
  production: [
    {
      name: 'Estácio',
      domains: ['admin.online.estacio.br', 'admin.cursoslivres.estacio.br'],
      apiUrl: 'https://api.online.estacio.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      lmsUrl: 'https://online.estacio.br',
      logoUrl: 'https://assets.olimpo.grupoq.io/morpheus/logo-dark.svg',
      disabledFeatures: [
        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'IBMEC',
      domains: ['admin.cursos.ibmec.br'],
      apiUrl: 'https://api.cursos.ibmec.br',
      lmsUrl: 'https://cursos.ibmec.br',
      googleId:
        '511931388600-mhua7vq2son61dgn6ide5sjm2v1aee4d.apps.googleusercontent.com',
      logoUrl: 'https://assets.olimpo.grupoq.io/demeter/logotype-h-dark.svg',
      disabledFeatures: [
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'Percursa',
      domains: ['admin.percursa.com'],
      apiUrl: 'https://apiv2.percursa.com',
      lmsUrl: 'https://percursa.com',
      logoUrl: 'https://assets.olimpo.grupoq.io/hephaestus/logo-light.svg',
      googleId:
        '266674794398-qqhoctvk6jnullddikrova9pehpks0ke.apps.googleusercontent.com',
      disabledFeatures: [
        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'QConcursos',
      domains: ['admin.pos.qconcursos.com'],
      apiUrl: 'https://api.pos.qconcursos.com',
      lmsUrl: 'https://app.pos.qconcursos.com',
      logoUrl:
        'https://assets.olimpo.grupoq.io/assets/persephone/dark-logo.svg',
      googleId:
        '529641470454-3rpkk7i7667vaj353sotocuhf5sbkbdc.apps.googleusercontent.com',
      disabledFeatures: [
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'Plural',
      domains: ['admin.estrelasdarevolucao.estacio.br'],
      apiUrl: 'https://api.estrelasdarevolucao.estacio.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      disabledFeatures: [
        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'Leone Maltz',
      domains: ['admin-leonemaltz.grupoq.io', 'admin.leonemaltz.com.br'],
      apiUrl: 'https://api.leonemaltz.com.br',
      googleId:
        '484927615520-k100spkqii647makmdboonq5n091bfsc.apps.googleusercontent.com',
      disabledFeatures: [
        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'Damásio',
      domains: ['admin-damasio.atlas.grupoq.io', 'admin.damasio.com.br'],
      lmsUrl: 'https://ensino.damasio.com.br',
      apiUrl: 'https://api-damasio.atlas.grupoq.io',
      logoUrl: 'https://assets.olimpo.grupoq.io/assets/themis/dark-logo.svg',
      googleId:
        '612147605254-kldiotvc9gp4kuhept424nttfnaelu7c.apps.googleusercontent.com',
      disabledFeatures: ['interactive_classes', 'libraries']
    },
    {
      name: 'Polos',
      domains: ['admin.capacitapolos.yduqs.com.br'],
      apiUrl: 'https://api.capacitapolos.yduqs.com.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      disabledFeatures: [
        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ]
    },
    {
      name: 'Demo',
      domains: ['admin-demo.opx.grupoq.io'],
      apiUrl: 'https://api-demo.opx.grupoq.io',
      googleId:
        '458277232320-ojmn61rorm5dc48egmdsp0l8lc3od3jn.apps.googleusercontent.com',
      disabledFeatures: ['manual_orders', 'undergraduate_courses']
    },
    {
      name: 'USP',
      domains: ['admin-usp.opx.grupoq.io'],
      apiUrl: 'https://api-usp.opx.grupoq.io',
      disabledFeatures: [
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'undergraduate_courses',
        'libraries'
      ],
      googleId:
        '961007583174-kg8l7s30gj5cgulkmt4a7e2g11npsufa.apps.googleusercontent.com'
    },
    {
      name: 'IBMEC Soluçes Corporativas',
      domains: ['admin-ibmecsolcorp.opx.grupoq.io', 'admin.solcorp.ibmec.br'],
      apiUrl: 'https://api-ibmecsolcorp.opx.grupoq.io',
      lmsUrl: 'https://app.solcorp.ibmec.br',
      disabledFeatures: ['manual_orders', 'undergraduate_courses'],
      googleId:
        '842505787047-77sv5srsghtgif367mci59hh6hkq1bhg.apps.googleusercontent.com'
    },
    {
      name: 'IBMEC Pós Parcerias',
      domains: [
        'admin-ibmecposparcerias.opx.grupoq.io',
        'admin.parcerias.ibmec.br'
      ],
      apiUrl: 'https://api-ibmecposparcerias.opx.grupoq.io',
      lmsUrl: 'https://app.parcerias.ibmec.br',
      disabledFeatures: [
        'manual_orders',
        'interactive_classes',
        'undergraduate_courses',
        'libraries'
      ],
      googleId:
        '1064361393394-72ur8dgvca8kk6f63ibevmem2mp0ljcc.apps.googleusercontent.com'
    },
    {
      name: 'How University',
      domains: ['admin.howuniversity.com.br'],
      apiUrl: 'https://api.howuniversity.com.br',
      lmsUrl: 'https://app.howuniversity.com.br',
      disabledFeatures: [
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries'
      ],
      googleId:
        '345706624231-rcat6lncuo2kmiunobcanvg2v49be5eb.apps.googleusercontent.com'
    },
    {
      name: 'Idomed',
      domains: ['admin.idomed.opx.grupoq.io', 'admin.cursos.idomed.com.br'],
      apiUrl: 'https://api.cursos.idomed.com.br',
      lmsUrl: 'https://app.cursos.idomed.com.br',
      disabledFeatures: ['manual_orders', 'undergraduate_courses', 'libraries'],
      googleId:
        '1055274702036-9156aag8in3jr69u9g669mquf11qq1d7.apps.googleusercontent.com'
    },
    {
      name: 'grupoQ',
      domains: ['admin.lxs.grupoq.io'],
      apiUrl: 'https://apilxs.grupoq.io',
      lmsUrl: 'https://lxs.grupoq.io',
      disabledFeatures: ['manual_orders', 'undergraduate_courses'],
      googleId:
        '21475016232-95mkmmfu2d1qtokt4ni7q65kcn6849mt.apps.googleusercontent.com'
    }
  ]
};

const tenant: ITenant | undefined = (tenantList[env] || []).find(tenant =>
  tenant.domains.some(domain =>
    new RegExp(domain).test(window.location.hostname)
  )
);

export const getTenant = (): ITenant => {
  if (!tenant) {
    throw 'TenantError';
  }

  return tenant;
};
