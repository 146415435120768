import { CommentShow, CommentsList } from 'pages/admin/comments';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'comments',
  label: 'Comentários Aulas',
  list: CommentsList,
  show: CommentShow,
  actions: ['list', 'show'],
  parentName: 'academic'
};

export default generateResources(resource);
