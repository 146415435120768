import AssetUpload from 'components/AssetUpload';
import SlugInput from '../../../components/form-items/SlugInput';
import useParentCategoriesSelect from '../../../hooks/useParentCategoriesSelect';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IProductCategory } from 'interfaces/product_categories';

export const ProductCategoryCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProductCategory>();
  return (
    <Create saveButtonProps={saveButtonProps} title="Criar categoria">
      <Form {...formProps} layout="vertical">
        <Form.Item label="Card" name={['media', 'card_image']} rules={[{}]}>
          <AssetUpload accept="image/png, image/jpeg" type="product_category" />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <SlugInput />
        <Form.Item
          label="Nome Curto"
          name="short_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          className="w-1/2"
          label="Categoria pai"
          name="parent_category_id"
        >
          <Select
            allowClear
            onDeselect={() =>
              formProps.form?.setFieldsValue({
                parent_category_id: null
              })
            }
            {...useParentCategoriesSelect({})}
            placeholder="Caso haja, selecione a categoria pai"
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
