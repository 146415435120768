import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import {
  IGraduateDiscipline,
  IGraduateDisciplineModules
} from 'interfaces/graduate_disciplines';
import { useMemo } from 'react';

export const GraduateDisciplineCreate = () => {
  const { formProps, saveButtonProps } = useForm<IGraduateDiscipline>({
    redirect: 'edit'
  });
  const { selectProps: modulesSelectProps, queryResult } =
    useSelect<IGraduateDisciplineModules>({
      resource: 'graduate_modules',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  const modules = useMemo(() => {
    return queryResult?.data?.data.map(module => {
      const {
        external_reference,
        name,
        course_external_reference,
        course_name,
        id
      } = module;

      const codModuleExternal = external_reference
        ? `${external_reference} - `
        : '';

      const codCourseExternal = course_external_reference
        ? `${course_external_reference} - `
        : '';

      const label = `Módulo: ${codModuleExternal} ${name} | Curso: ${codCourseExternal} ${course_name}`;

      return {
        label,
        value: id
      };
    });
  }, [queryResult?.data?.data]);

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Criar disciplina"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Duração (minutos)"
          name="duration_in_minutes"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Modulos" name="module_ids">
          <Select {...modulesSelectProps} mode="multiple" options={modules} />
        </Form.Item>
      </Form>
    </Create>
  );
};
