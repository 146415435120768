import { useEffect, useState } from 'react';
import {
  IClassroom,
} from '../../../interfaces/classrooms';
import Tree from 'components/tree/Tree';
import { ITree } from 'interfaces/tree';
import { Form } from 'antd';

export const ClassroomContent = ({
  classroom,
  onChange
}: {
  classroom: IClassroom;
  onChange?: (data: ITree) => void;
}) => {
  const [contentTree, setContentTree] = useState<ITree>(classroom.content_tree);

  const onUpdateTree = (data: ITree) => {
    setContentTree(data);
    onChange?.(data);
  };

  return (
    <>
     <Form.Item name={['content_tree']}>
      <Tree tree={contentTree} onUpdate={onUpdateTree} />
     </Form.Item>
    </>
  );
};
