import {
  ProfessorCreate,
  ProfessorEdit,
  ProfessorShow,
  ProfessorsList
} from 'pages/academic/professor';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'professors',
  label: 'Professores',
  list: ProfessorsList,
  create: ProfessorCreate,
  edit: ProfessorEdit,
  show: ProfessorShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'academic'
};

export default generateResources(resource);
