import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { ILibrary } from 'interfaces/libraries';
import { Space, Table } from 'antd';

export const LibrariesList = () => {
  const { tableProps } = useTable<ILibrary, HttpError, { byName: string }>({
    resource: 'prometheus/v2/libraries',
    onSearch: ({ byName }) => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />

        <Table.Column<ILibrary>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
