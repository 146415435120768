import routerProvider from '@refinedev/react-router-v6/legacy';
import { IComment } from 'interfaces/comments';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Link } = routerProvider;
const { Title, Text } = Typography;

export const CommentShow = () => {
  const { queryResult } = useShow<IComment>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar comentário de aula">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Autor</Title>
      <Text>
        {record?.user.name} ({record?.user.email})
      </Text>
      <Title level={5}>Texto</Title>
      <Text>{record?.text}</Text>
      <Title level={5}>Localização</Title>
      {record?.parent_kind !== 'comment' && (
        <Text>{record?.parent_description}</Text>
      )}

      {record?.parent_kind === 'comment' && (
        <Link to={`/academic/comments/show/${record?.parent_reference}`}>
          <Text style={{ color: 'blue' }}>{record?.parent_description}</Text>
        </Link>
      )}
    </Show>
  );
};
