import { OrderList, OrderShow } from 'pages/ecommerce/orders';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'orders',
  label: 'Pedidos Realizados',
  list: OrderList,
  show: OrderShow,
  actions: ['list', 'show'],
  parentName: 'sales'
};

export default generateResources(resource);
