import {
  AcademicRecordEdit,
  AcademicRecordShow
} from 'pages/academic/academic_records';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate/academic_records',
  label: 'Histórico acadêmico',
  show: AcademicRecordShow,
  edit: AcademicRecordEdit,
  actions: ['show', 'edit', 'list'],
  parentName: 'academic'
};

export default generateResources(resource);
