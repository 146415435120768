/* eslint-disable unicorn/filename-case */
export const academicIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.334 9.66704V7.66332C11.334 7.54367 11.334 7.48384 11.3158 7.43102C11.2997 7.38432 11.2734 7.34177 11.2388 7.30647C11.1997 7.26656 11.1462 7.2398 11.0392 7.18629L8.00065 5.66704M2.66732 6.3337V10.8714C2.66732 11.1194 2.66732 11.2434 2.706 11.3519C2.74019 11.4479 2.79592 11.5347 2.8689 11.6058C2.95146 11.6862 3.06417 11.7378 3.28955 11.8411L7.55622 13.7967C7.71971 13.8716 7.80145 13.9091 7.88661 13.9239C7.96207 13.937 8.03923 13.937 8.11469 13.9239C8.19985 13.9091 8.28159 13.8716 8.44508 13.7967L12.7118 11.8411C12.9371 11.7378 13.0498 11.6862 13.1324 11.6058C13.2054 11.5347 13.2611 11.4479 13.2953 11.3519C13.334 11.2434 13.334 11.1194 13.334 10.8714V6.3337M1.33398 5.66704L7.76214 2.45296C7.84959 2.40923 7.89332 2.38737 7.93919 2.37876C7.97981 2.37114 8.02149 2.37114 8.06212 2.37876C8.10798 2.38737 8.15171 2.40923 8.23917 2.45296L14.6673 5.66704L8.23917 8.88111C8.15171 8.92484 8.10798 8.9467 8.06212 8.95531C8.02149 8.96293 7.97981 8.96293 7.93919 8.95531C7.89332 8.9467 7.84959 8.92484 7.76214 8.88111L1.33398 5.66704Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
