import { adminIcon } from '../assets/icons/admin';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'admin',
  label: 'Admin',
  icon: adminIcon
};

export default generateResources(resource);
