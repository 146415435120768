import React from 'react';
import { Layout as AntdLayout, Grid } from 'antd';
import { LayoutProps } from '@refinedev/core';
import { ThemedLayoutV2 } from '@refinedev/antd';
import { styled } from '@qcx/ui';

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea
}) => {
  const breakpoint = Grid.useBreakpoint();

  return (
    <ThemedLayoutV2
      Sider={Sider}
      Header={Header}
      Footer={Footer}
      OffLayoutArea={OffLayoutArea}
    >
      <StyledContent style={{ backgroundColor: '#FFFFFF', borderRadius: 6 }}>
        <div
          style={{
            padding: breakpoint.sm ? '12px 24px 16px' : 12,
            minHeight: 360
          }}
        >
          {children}
        </div>
      </StyledContent>
    </ThemedLayoutV2>
  );
};

const StyledContent = styled(AntdLayout.Content, {
  backgroundColor: '#FFFFFF',
  borderRadius: 6,
  ' .ant-breadcrumb': {
    marginBottom: '$2'
  },
  ' .ant-page-header.ant-page-header-has-breadcrumb': {
    paddingTop: 12
  }
});
