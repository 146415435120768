import { IAssessmentAttempt } from 'interfaces/assessments';
import { IAssignmentAttempt } from 'interfaces/assignments';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { formatDate } from 'services/date';

interface AttemptsTableProps {
  attempts: (IAssignmentAttempt | IAssessmentAttempt)[];
  evaluationType: IClassroomEvaluation['evaluation_type'];
}

export const AttemptsTable = ({
  attempts,
  evaluationType
}: AttemptsTableProps) => {
  return (
    <div className="flex flex-col gap-3 py-4 pl-12">
      {attempts.map((attempt, index) => (
        <div className="flex" key={attempt.id}>
          <div className="flex-1">
            <Link
              to={
                evaluationType === 'Assessment'
                  ? `/academic/assessments/attempts/show/${attempt.id}`
                  : `/academic/assignment_attempts/show/${attempt.id}`
              }
              className="!p-0 text-blue-600 hover:text-blue-800"
            >
              Tentativa {index + 1} -
              {attempt?.completed_at
                ? ` Concluída em ` +
                  formatDate(attempt?.completed_at, 'DD/MM/YYYY')
                : ' Não finalizada'}
            </Link>
          </div>
          <Typography.Text className="w-[84px] text-center">
            {attempt?.completed_at ? (
              <>
                Nota{' '}
                {attempt.score && attempt.score > 0
                  ? attempt.score
                  : '0' || '-'}
              </>
            ) : (
              '-'
            )}
          </Typography.Text>
        </div>
      ))}
    </div>
  );
};
