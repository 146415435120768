/* eslint-disable unicorn/filename-case */
// see antd tag's colors

const STATUS_COLOR_MAP = new Map([
  ['initial', 'orange'],
  ['created', 'processing'],
  ['approved', 'green'],
  ['returned', 'default'],
  ['disabled', 'default'],
  ['expired', 'orange'],
  ['active', 'green'],
  ['pending', 'orange'],
  ['answered', 'green'],
  ['expired', 'warning'],
  ['pending', 'warning'],
  ['closed', 'default'],
  ['unknown', 'default'],
  ['started', 'processing'],
  ['pending_evaluation', 'orange'],
  ['evaluation_started', 'processing'],
  ['evaluated', 'green'],
  ['inactive', 'error'],
  ['passed', 'green'],
  ['failed', 'error'],
  ['submitted', 'processing'],
  ['evaluated', 'success'],
  ['passed', 'success'],
  ['canceled', 'error'],
  ['cancelled', 'error'],
  ['rejected', 'error'],
  ['validating', 'blue'],
  ['exempted', 'blue'],
  ['regular', 'blue'],
  ['replacement', 'orange'],
  ['scored', 'default'],
  ['nullified', 'red']
]);

export const tagColorByStatus = (status: string): string =>
  STATUS_COLOR_MAP.get(status) || status;
