import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { IAssessment } from 'interfaces/assessments';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useState } from 'react';

export const AssessmentCreate = () => {
  const { form, formProps, saveButtonProps } = useForm<IAssessment>({
    redirect: 'edit'
  });

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const [parentType, setParentType] = useState('Course');

  const toogleParent = (value: string) => {
    setParentType(value);
    form.setFieldsValue({ parent_id: '', parent_type: value });
  };
  const selectOptions = [
    { label: 'Curso', value: 'Course' },
    { label: 'Disciplina', value: 'Graduate::Discipline' }
  ];

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar avaliação">
      <Form form={form} {...formProps} layout="vertical">
        <Form.Item name="parent_type" hidden initialValue="Course">
          <Input />
        </Form.Item>
        <Form.Item label="Nome" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item label="Selecione o tipo" valuePropName="checked">
          <Select
            options={selectOptions}
            defaultValue="Course"
            onChange={toogleParent}
          />
        </Form.Item>
        <Form.Item
          label={parentType === 'Graduate::Discipline' ? 'Disciplina' : 'Curso'}
          name="parent_id"
          required
        >
          {parentType === 'Graduate::Discipline' ? (
            <Select {...disciplineSelectProps} />
          ) : (
            <Select {...courseSelectProps} />
          )}
        </Form.Item>
        <Row gutter={40}>
          <Col>
            <Form.Item
              label="Tentativas"
              name="max_attempts"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              hidden
              name="time_in_seconds"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Duração"
              name="time_in_minutes"
              rules={[{ required: true }]}
            >
              <InputNumber
                addonAfter="min"
                min={0}
                className="max-w-[120px]"
                onChange={value =>
                  form.setFieldsValue({ time_in_seconds: Number(value) * 60 })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Quantidade de questões"
              tooltip="Máximos de questões por prova"
              name="question_amount"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col>
            <Form.Item
              label="Peso"
              name="weight"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Nota mínima (0 - 100)"
              name="passing_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
