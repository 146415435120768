import { IAcademicRecord } from 'interfaces/academic_record';
import { IGraduateCourseEnrollment } from 'interfaces/graduate_course_enrollments';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useSelect } from '@refinedev/antd';

export const fetchGraduateCoursesEnrollments = (userId: string) => {
  const { selectProps: courseSelectProps, queryResult: courseQueryResult } =
    useSelect<IGraduateCourseEnrollment>({
      resource: `graduate/courses/enrollments`,
      fetchSize: 999,

      filters: [
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userId
        }
      ],

      onSearch: () => [
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userId
        }
      ],

      pagination: {
        mode: 'server',
        mode: 'server'
      }
    });

  return {
    courseSelectProps,
    coursesEnrollments: courseQueryResult.data?.data || []
  };
};

export const fetchGraduateDisciplines = (courseId?: string) => {
  const { queryResult: disciplineQueryResult } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      fetchSize: 999,

      filters: [
        {
          field: 'filter[by_graduate_course_id]',
          operator: 'eq',
          value: courseId
        }
      ],

      onSearch: () => [
        {
          field: 'filter[by_graduate_course_id]',
          operator: 'eq',
          value: courseId
        }
      ],

      pagination: {
        mode: 'server',
        mode: 'server'
      }
    }
  );

  return disciplineQueryResult.data?.data || [];
};

export const fetchAcademicRecorsByCourseId = (courseId?: string) => {
  const { queryResult: academicRecordQueryResult } = useSelect<IAcademicRecord>(
    {
      resource: 'graduate/academic_records',

      filters: [
        {
          field: 'filter[by_course_enrollment_id]',
          operator: 'eq',
          value: courseId
        }
      ],

      pagination: {
        mode: 'server',
        mode: 'server'
      }
    }
  );

  return academicRecordQueryResult.data?.data || [];
};

export const checkAcademicRecordAndGetDisciplines = (
  graduateDisciplines: IGraduateDiscipline[],
  academicRecords: IAcademicRecord[]
) => {
  if (!graduateDisciplines) {
    return;
  }

  return academicRecords && academicRecords.length > 0
    ? graduateDisciplines.filter(discipline => {
        return academicRecords.some(academicRecord => {
          return (
            academicRecord.discipline.id === discipline.id &&
            academicRecord.status !== 'approved'
          );
        });
      })
    : graduateDisciplines;
};
