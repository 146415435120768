import { Col, Row } from 'antd';
import { CrudFilters, HttpError, useNotification } from '@refinedev/core';
import { IAssignment } from '../../../interfaces/assignments';
import {
  IListAssignmentFilter,
  ListAssignmentFilter
} from './components/list-filter';
import { List, useTable } from '@refinedev/antd';
import { ListAssignmentsTable } from './components/list-table';
import { getAPI } from '../../../requests/api';
import { useMemo } from 'react';

export const AssignmentList = () => {
  const { open, close } = useNotification();
  const { tableProps, searchFormProps } = useTable<
    IAssignment,
    HttpError,
    IListAssignmentFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_parent_id, by_parent_type, by_name, with_pending_attempts } =
        params;

      filters.push(
        {
          field: 'filter[by_parent_id]',
          operator: 'eq',
          value: by_parent_id
        },
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: by_parent_type
        },
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: by_name
        },
        {
          field: 'filter[with_pending_attempts]',
          operator: 'eq',
          value: with_pending_attempts
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: 'Course'
        }
      ]
    }
  });

  const updateAttemptStatus = async (recordId: string) => {
    try {
      const { data, status } = await getAPI().put(
        `/admin/evaluations/assignments/attempts/${recordId}`
      );

      if (status === 200 && data.id) {
        const attemptId = data.id;
        window.location.href = `/academic/assignment_attempts/edit/${attemptId}`;
      }
    } catch {
      open?.({
        type: 'success',
        description: 'Tudo em dia',
        message: 'Nada para ser corrigido aqui :)',
        key: recordId
      });

      setTimeout(() => {
        close?.(recordId);
      }, 1500);
    }
  };

  const selectedType = useMemo(
    () => searchFormProps.form?.getFieldValue('by_parent_type'),
    [searchFormProps]
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <ListAssignmentFilter searchFormProps={searchFormProps} />
          <ListAssignmentsTable
            tableProps={tableProps}
            type={selectedType}
            updateAttemptStatus={updateAttemptStatus}
          />
        </List>
      </Col>
    </Row>
  );
};
