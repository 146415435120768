import {
  CourseCreate,
  CourseEdit,
  CourseList,
  CourseShow
} from 'pages/courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'courses',
  label: 'Cursos Avulsos',
  list: CourseList,
  show: CourseShow,
  edit: CourseEdit,
  create: CourseCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: '_courses'
};

export default generateResources(resource);
