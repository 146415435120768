import * as Icons from '@ant-design/icons';
import FilterForm from '../../../components/forms/FilterForm';
import { AssessmentQuestionList } from './components/list-questions-table';
import { Col, Form, Input, Row, Space, Tooltip, Typography } from 'antd';
import { CrudFilters, HttpError, useShow } from '@refinedev/core';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { Show, useTable } from '@refinedev/antd';

// import { AssessmentQuestionList } from '../assessment_questions/list';
interface IFilter {
  statement: string;
  assessment_id: string;
}

export const AssessmentShow = () => {
  const { queryResult, showId } = useShow<IAssessment>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { tableProps, searchFormProps } = useTable<
    IAssessmentQuestion,
    HttpError,
    IFilter
  >({
    resource: 'assessment_questions',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { statement } = params;

      filters.push(
        {
          field: 'filter[assessment]',
          operator: 'eq',
          value: showId
        },
        {
          field: 'filter[statement]',
          operator: 'eq',
          value: statement
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[assessment]',
          operator: 'eq',
          value: showId
        }
      ]
    }
  });

  const assessmentInfo = [
    { label: 'Nome', value: record?.name },
    {
      label: record?.parent_type === 'Course' ? 'Curso' : 'Disciplina',
      value: record?.course_name
    },
    {
      label: 'Duração (minutos)',
      value: record?.time_in_seconds ? record?.time_in_seconds / 60 : null
    },
    { label: 'Tentativas', value: record?.max_attempts },
    { label: 'Peso', value: record?.weight },
    { label: 'Nota mínima (0-100)', value: record?.passing_score },
    {
      label: 'Quantidade de questões',
      value: record?.question_amount,
      tooltip:
        'É necessário ter no mínimo essa quantidade de questões para gerar a prova'
    }
  ];

  return (
    <Show isLoading={isLoading} title="Visualizar avaliação">
      <Row gutter={[16, 24]} className="py-4">
        {assessmentInfo.map(({ label, value, tooltip }, index) => (
          <Col xs={24} md={6} key={index}>
            <Space direction="vertical">
              <Space direction="horizontal">
                <Typography.Text strong>{label}</Typography.Text>
                {tooltip && (
                  <Tooltip className="mb-1" title={tooltip}>
                    <Icons.QuestionCircleOutlined />
                  </Tooltip>
                )}
              </Space>
              <Typography.Text>{value ?? '--'}</Typography.Text>
            </Space>
          </Col>
        ))}
      </Row>

      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Enunciado" name="statement">
          <Input placeholder="Digite parte do enunciado" />
        </Form.Item>
      </FilterForm>

      {record?.id && (
        <AssessmentQuestionList
          tableProps={tableProps}
          searchFormProps={searchFormProps}
        />
      )}
    </Show>
  );
};
