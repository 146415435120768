import { IGraduateCourse } from 'interfaces/graduate_courses';
import { Typography } from 'antd';

const { Title, Text } = Typography;

export interface CourseInfoProps {
  course?: IGraduateCourse;
}

export const CourseInfo = ({ course }: CourseInfoProps) => {
  if (!course) {
    return null;
  }
  return (
    <>
      <Title level={5}>ID</Title>
      <Text>{course?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{course?.name}</Text>
      <Title level={5}>Tipo</Title>
      <Text>{course?.kind === 'graduate' ? 'Pós Graduação' : 'Graduação'}</Text>
      <Title level={5}>Duração (horas)</Title>
      <Text>{course?.duration_in_hours}</Text>
    </>
  );
};
