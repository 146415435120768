import {
  ICreateReport,
  IDocumentDownload,
  IReportData
} from 'interfaces/declarations';
import { IReportReason, IReportStatus } from 'interfaces/reports';
import { IUser } from 'interfaces/users';
import { OpenNotificationParams } from '@refinedev/core';
import { fetchSummaryDocumentation } from './graduate-enrollment/summary';
import { getAPI } from 'requests/api';

export const REPORT_STATUS_MAP: Record<IReportStatus, string> = {
  pending: 'Pendente',
  accepted: 'Aprovado',
  rejected: 'Rejeitado'
};

export const REPORT_REASON_MAP: Record<IReportReason, string> = {
  spam: 'Conteúdo comercial indesejado ou spam',
  offensive: 'Discurso de ódio ou violência explícita',
  harassment: 'Assédio ou bullying',
  misinformation: 'Desinformação',
  other: 'Outro'
};

export const REPORT_STATUSES_WITH_COLOR_MAP: Record<
  IReportStatus,
  { text: string; color: string }
> = {
  pending: { text: 'Pendente', color: 'default' },
  accepted: { text: 'Aprovado', color: 'green' },
  rejected: { text: 'Rejeitado', color: 'red' }
};

const handleReportStatusAndDownload = async (
  report: ICreateReport,
  errorCallback: (params: OpenNotificationParams) => void
): Promise<void> => {
  try {
    const reportData = await getAPI().get<IReportData>(
      `/admin/reports/${report.id}`
    );

    if (reportData.data.status === 'error') {
      throw new Error('Error ao gerar declaração');
    }

    if (reportData.data.status === 'processed') {
      const documentUrl = await getAPI().get<IDocumentDownload>(
        `/public/reports/files/${reportData.data?.files?.[0]?.id}/download`
      );

      handleDownloadURL(documentUrl.data);
    } else {
      setTimeout(
        () => handleReportStatusAndDownload(report, errorCallback),
        1000
      );
    }
  } catch {
    errorCallback({
      type: 'error',
      message:
        'Erro ao gerar sua declaração, tente novamente em alguns instantes.',
      description: 'Ops!'
    });
  }
};

const handleDownloadURL = (reportData: IDocumentDownload) => {
  if (reportData.url) {
    window.open(reportData.url, '_self');
    return null;
  }
};

export const handleDownloadDeclaration = async (
  courseEnrollmentId: string,
  open: (params: OpenNotificationParams) => void
) => {
  const reportRes = await getAPI().post<ICreateReport>('/admin/reports', {
    kind: 'graduate_enrollment_academic_record',
    inputs: { graduate_course_enrollment_id: courseEnrollmentId }
  });

  return handleReportStatusAndDownload(reportRes.data, open!);
};

export const hasPersonalInfo = (user: IUser) => {
  return !!(
    user?.personal_info?.full_name && user?.personal_info?.document_info
  );
};

export const getUserDocumentation = async (
  enrollmentId: string,
  open: (params: OpenNotificationParams) => void
) => {
  try {
    return await fetchSummaryDocumentation(enrollmentId);
  } catch {
    open?.({
      type: 'error',
      message: 'Erro ao recuperar documentações.',
      description: 'Ops!'
    });
  }
};
