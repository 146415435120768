import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IGroup } from 'interfaces/epimetheus/groups';

export const GroupCreate = () => {
  const { formProps, saveButtonProps } = useForm<IGroup>({
    redirect: 'edit'
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar gerenciador de tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
