import FilterForm from 'components/forms/FilterForm';
import React from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form, Input, Table, Typography } from 'antd';
import { HttpError } from '@refinedev/core';
import { IEnrollment } from 'interfaces/enrollments';
import { IUser } from 'interfaces/users';
import { ShowButton, TextField, useTable } from '@refinedev/antd';
import { formatDate, replaceTimeZone } from 'services/date';

interface IFilter {
  name: string;
  email: string;
  startsAfter: dayjs.Dayjs;
  endsBefore: dayjs.Dayjs;
}

const TabStudents: React.FC = () => {
  const resource = `academic/users`;
  const { tableProps, searchFormProps } = useTable<IUser, HttpError, IFilter>({
    resource: 'graduate/courses/enrollments',

    onSearch: ({ name, email, startsAfter, endsBefore }) => {
      return [
        {
          field: 'filter[search_user_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: email
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: startsAfter && replaceTimeZone(startsAfter)
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: endsBefore && replaceTimeZone(endsBefore)
        }
      ];
    }
  });
  return (
    <>
      <Typography.Title level={5}>Alunos do curso</Typography.Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome do aluno" name="name">
          <Input
            style={{ minWidth: 250 }}
            placeholder="Informe o nome do aluno"
          />
        </Form.Item>
        <Form.Item label="Email do aluno" name="email">
          <Input
            style={{ minWidth: 250 }}
            placeholder="Informe o email do aluno"
          />
        </Form.Item>
        <Form.Item label="Início a partir de" name="startsAfter">
          <DatePicker
            className="w-full"
            style={{ minWidth: 250 }}
            placeholder="dia/mês/ano"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item label="Término em" name="endsBefore">
          <DatePicker
            className="w-full"
            style={{ minWidth: 250 }}
            placeholder="dia/mês/ano"
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={['user', 'name']}
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['user', 'email']}
          key="email"
          title="Email"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IEnrollment>
          key="course"
          title="Data de início"
          render={(_, record) => {
            return (
              <TextField
                value={formatDate(String(record?.created_at), 'lll')}
              />
            );
          }}
        />
        <Table.Column<IEnrollment>
          dataIndex="course-actions"
          key="course-actions"
          title="Ações"
          render={(_, record) => {
            return (
              <ShowButton
                hideText
                size="small"
                resource={resource}
                recordItemId={record.user.id}
              />
            );
          }}
        />
      </Table>
    </>
  );
};

export default TabStudents;
