import 'react-quill/dist/quill.snow.css';
import AssetUpload from 'components/AssetUpload';
import ReactQuill from 'react-quill';
import SlugInput from '../../../components/form-items/SlugInput';
import useParentCategoriesSelect from '../../../hooks/useParentCategoriesSelect';
import { CanAccess } from '@refinedev/core';
import { Checkbox, Form, Input, Select, Switch, Tabs, Typography } from 'antd';
import { Edit, TagField, useForm, useSelect } from '@refinedev/antd';
import { IProductCategory } from 'interfaces/product_categories';
import { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { IProduct } from 'interfaces/products';

const onValidate = async (file: UploadFile, minW: number): Promise<boolean> => {
  let isValid = false;
  const img = new Image();
  img.src = window.URL?.createObjectURL(file as unknown as File);

  const loadImage = () => {
    return new Promise(resolve => {
      /* eslint-disable-next-line unicorn/prefer-add-event-listener */
      img.onload = () => {
        isValid = img.width >= minW;
        resolve(true);
      };
    });
  };

  await loadImage();

  return isValid;
};

export const mobileValidation = async (file: UploadFile) => {
  return await onValidate(file, 992);
};

export const desktopValidation = async (file: UploadFile) => {
  return await onValidate(file, 1440);
};

export const ProductCategoryEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IProductCategory>(
    { redirect: 'edit' }
  );

  const [shouldEditSlug, setShouldEditSlug] = useState<boolean>(false);

  const children = queryResult?.data?.data?.products_categories;

  const parentCategoriesProps = useParentCategoriesSelect({
    forbiddenId: queryResult?.data?.data.id,
    defaultValue: queryResult?.data?.data.parent_category_id
  });

  const { selectProps: productsSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data?.info?.highlighted_products || [],
    pagination: {
      mode: 'server'
    },
    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value: value
      }
    ]
  });

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar categoria">
      <Form {...formProps} layout="vertical">
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Informação" key="info">
            <Form.Item label="Esconder" name="hidden" valuePropName="checked">
              <Switch />
            </Form.Item>

            <Form.Item
              label="Card"
              name={['media', 'card_image']}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product_category"
                src={queryResult?.data?.data?.media?.card_image}
              />
            </Form.Item>
            <CanAccess resource="product_categories" action="slug">
              <Checkbox
                onChange={value => {
                  setShouldEditSlug(value.target.checked);
                }}
              >
                Eu assumo a RESPONSABILIDADE de alterar a SLUG e isso poderá
                quebrar o SEO e a experiência do usuário
              </Checkbox>
            </CanAccess>
            <SlugInput disabled={!shouldEditSlug} />
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nome Curto"
              name="short_name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Descrição"
              name="description"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Input />
            </Form.Item>

            {/* TODO Extract this */}
            {children && children?.length > 0 ? (
              <>
                <Typography.Text strong className="mr-4">
                  Subcategorias:
                </Typography.Text>
                {children.map((item: IProductCategory, idx: number) => (
                  <TagField
                    style={{ border: 'none', margin: 4 }}
                    color="cyan"
                    key={`category-${idx}`}
                    value={item.name}
                    // TODO NOT WORKING
                    // onClick={() =>
                    //   edit('ecommerce/products/categories', item.id)
                    // }
                  />
                ))}
              </>
            ) : (
              <Form.Item
                className="w-1/2"
                label="Categoria pai"
                name="parent_category_id"
              >
                <Select
                  allowClear
                  onDeselect={() =>
                    formProps.form?.setFieldsValue({
                      parent_category_id: null
                    })
                  }
                  {...parentCategoriesProps}
                  placeholder="Caso haja, selecione a categoria pai"
                />
              </Form.Item>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Conteúdo" key="content">
            <Form.Item name={['info', 'about']} label="Sobre">
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item name={['info', 'video_url']} label="Url do vídeo">
              <Input />
            </Form.Item>
            <Form.Item name={['info', 'highlighted_products']} label="Produtos em destaque">
              <Select mode="multiple" allowClear {...productsSelectProps} />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assets" key="assets">
            <Form.Item
              label="Ícone"
              name={['media', 'icon']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/svg+xml"
                type="product_category"
                src={queryResult?.data?.data?.media?.icon}
              />
            </Form.Item>
            <Form.Item
              label="Capa versão desktop"
              name={['media', 'hero']}
              tooltip="Largura mínima: 1440px"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/svg+xml"
                type="product_category"
                src={queryResult?.data?.data?.media?.hero}
                onValidate={desktopValidation}
              />
            </Form.Item>
            <Form.Item
              label="Capa versão mobile"
              tooltip="Largura mínima: 992px"
              name={['media', 'hero_mobile']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/svg+xml"
                type="product_category"
                src={queryResult?.data?.data?.media?.hero_mobile}
                onValidate={mobileValidation}
              />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};
