import * as Icons from '@ant-design/icons';
import {
  Button, Form, Modal,
  Select,
  Space,
  Table, Typography
} from 'antd';
import {
  DeleteButton,
  ShowButton, TextField, useForm, useModalForm,
  useSelect,
  useTable
} from '@refinedev/antd';
import {
  IDisciplineModule,
  IGraduateDisciplineModule
} from 'interfaces/graduate_discipline_module';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IGraduateDiscipline, IGraduateDisciplineShow } from 'interfaces/graduate_disciplines';
import { Stack } from '@qcx/ui';
import { useCreate, useNotification } from '@refinedev/core';

const { Title, Text } = Typography;

export const disciplineModulesKind = {
  mandatory: 'Obrigatória',
  optional: 'Optativa'
};

export const TabDisciplines = () => {
  const { id: courseId } = useParams();
  const [disciplineEdit, setDisciplineEdit] = useState<IDisciplineModule>();
  const { open } = useNotification();
  const resource = 'graduate_disciplines';

  const {
    modalProps: editDisciplineModalProps,
    form: editDisciplineForm,
    formProps: editDisciplineFormProps,
    show: editDisciplineModalShow
  } = useModalForm<IGraduateDisciplineModule>({
    resource,
    action: 'edit',
    redirect: false,
    successNotification: () => {
      return {
        description: 'Sucesso!',
        message: 'Disciplina alterada com sucesso',
        type: 'success'
      };
    }
  });

  const { tableProps, tableQueryResult } = useTable<IGraduateDiscipline>({
    resource,

    filters: {
      initial: [
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        }
      ]
    }
  });

  const { form } = useForm();
  const {
    selectProps: disciplineSelectProps,
    queryResult: disciplineQueryResult
  } = useSelect<IGraduateDisciplineShow>({
    resource,
    optionLabel: (value: IGraduateDisciplineShow) =>
      `${
        value.external_reference == null ? '' : value.external_reference + ' -'
      } ${value.name}`,
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });
  const { mutate: createDiscipline } = useCreate();
  const createResource = 'courses/disciplines';
  const destroyResource = 'courses/disciplines';
  const handleSubmit = useCallback(async () => {
    await form
      .validateFields()
      .then(() => {
        const disciplineId = form.getFieldValue('discipline_id');

        createDiscipline(
          {
            resource: createResource,
            values: {
              discipline_id: disciplineId,
              course_id: courseId
            },
            successNotification: () => {
              return {
                description: 'Sucesso!',
                message: 'Disciplina vinculada ao módulo com sucesso!',
                type: 'success'
              };
            }
          },
          {
            onSuccess: () => {
              tableQueryResult.refetch();
              form.resetFields();
            }
          }
        );
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: `Favor conferir todos os campos obrigatórios.`,
          description: 'Error ao criar registro!'
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, disciplineQueryResult, courseId]);

  const handleEditDiscipline = useCallback(
    (record: IDisciplineModule) => () => {
      setDisciplineEdit(record);
      editDisciplineModalShow(record.disciplineModuleId);
    },
    [editDisciplineModalShow]
  );

  return (
    <>
    <Form form={form}>
      <Stack css={{ gap: '$2', justifyContent: 'space-between' }}>
        <Form.Item
          style={{ flex: 1 }}
          label="Disciplina"
          name="discipline_id"
          rules={[{ required: true, message: 'Disciplina é obrigatória' }]}
        >
          <Select
            placeholder="Selecione a disciplina ..."
            {...disciplineSelectProps}
            // options={disciplines}
            style={{ minWidth: 200 }}
          />
        </Form.Item>
        <Button onClick={handleSubmit}>Inserir</Button>
      </Stack>
    </Form>
      <Table {...tableProps}>
        <Table.Column
          className="w-[60%]"
          title="Nome"
          dataIndex="name"
          key="name"
        />
        <Table.Column
          title="Código externo"
          dataIndex="external_reference"
          key="external_reference"
        />
        <Table.Column<IDisciplineModule>
          align="right"
          title="Ações"
          dataIndex="actions"
          key="actions"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton
                  size="small"
                  hideText
                  resource="graduate_disciplines"
                  recordItemId={record.id}
                />
                <DeleteButton
                  danger={false}
                  hideText
                  icon={<Icons.DeleteOutlined />}
                  size="small"
                  resource={destroyResource}
                  recordItemId={`${record.id}?course_id=${courseId}`}
                  onSuccess={() => {
                    tableQueryResult.refetch();
                  }}
                  successNotification={() => {
                    return {
                      description: 'Sucesso!',
                      message:
                        'Disciplina removida do módulo com sucesso',
                      type: 'success'
                    };
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
};
