import { coursesIcon } from '../assets/icons/courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: '_courses',
  label: 'Montagem Cursos',
  icon: coursesIcon
};

export default generateResources(resource);
