import { IDamFileOptions } from 'interfaces/dam_videos';
import { getAPI } from 'requests/api';

export const videoPublish = async (id: string): Promise<null> => {
  return getAPI().post(`/admin/prometheus/videos/${id}/publish`);
};

export const downloadFileOption = async (
  file_id: string,
  option_id: string
): Promise<IDamFileOptions> => {
  const { data } = await getAPI().get(
    `/admin/prometheus/files/${file_id}/options/${option_id}/download`
  );

  return data;
};

export const videoUnpublish = async (id: string): Promise<null> => {
  return getAPI().post(`/admin/prometheus/videos/${id}/unpublish`);
};
