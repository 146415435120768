/* eslint-disable unicorn/filename-case */
import { ICourse } from 'interfaces/courses';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useMemo, useState } from 'react';
import { useSelect } from '@refinedev/antd';

function useSelectCourseByType() {
  const [selectedType, setSelectedType] = useState('Course');

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const selectProps = useMemo(() => {
    return selectedType === 'Course'
      ? courseSelectProps
      : disciplineSelectProps;
  }, [courseSelectProps, disciplineSelectProps, selectedType]);

  return {
    selectedType,
    setSelectedType,
    selectProps
  };
}

export default useSelectCourseByType;
