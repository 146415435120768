import ConfirmationModal from 'components/modal/ConfirmationModal';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Button,
  Icon,
  Stack,
  Text
} from '@qcx/ui';
import { Col, Row, Space, Typography } from 'antd';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { IOrderPaymentPlansShow } from 'interfaces/orders';
import { PlanContentTable } from 'components/payment_plans/PlanContentTable';
import { Show, useModal } from '@refinedev/antd';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { paymentPlanStatus } from 'services/payment-plan';
import {
  useApiUrl,
  useCustomMutation,
  useParsed,
  useShow
} from '@refinedev/core';

const { Title } = Typography;

const userHeadTags = (summary: IOrderPaymentPlansShow['summary']) => [
  { title: 'Nome', text: summary.table.user.name, allowCopy: false },
  { title: 'CPF', text: summary.table.user.document_info, allowCopy: false },
  { title: 'CEP', text: summary.table.user.postal_code, allowCopy: false },
  { title: 'E-mail', text: summary.table.user.email, allowCopy: true },
  { title: 'Telefone', text: summary.table.user.phone_number, allowCopy: true }
];

const orderHeadTags = (summary: IOrderPaymentPlansShow['summary']) => [
  {
    title: 'Valor total',
    text: formatMoney(summary.table.order.total_amount),
    allowCopy: false
  },
  {
    title: 'Valor pago',
    text: formatMoney(summary.table.order.paid_amount),
    allowCopy: false
  },
  {
    title: 'Valor inadimplente',
    text: formatMoney(summary.table.order.debit_amount),
    allowCopy: false
  },
  {
    title: 'Data da compra',
    text: formatDate(summary.table.order.ordered_at, 'DD/MM/YY'),
    allowCopy: false
  }
];

export const OrderPaymentPlanShow = () => {
  const { params } = useParsed<Record<string, string>>();
  const { id } = params as { id: string };

  const { queryResult } = useShow<IOrderPaymentPlansShow>({
    resource: 'payment_plans',
    id: id
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading} title="Visualização do pedido">
        {!isLoading && (
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Col>
              <Title level={4}>Detalhes do aluno</Title>
              <div className="border rounded-lg p-2 mb-2">
                <Row>
                  {record?.summary &&
                    userHeadTags(record?.summary).map(item => (
                      <Col key={item.title} md={4} style={{ margin: 10 }}>
                        <Title level={5}>{item.title}</Title>

                        <div className="flex gap-2">
                          <Text>{item.text || '-'}</Text>
                          {item.allowCopy && item.text && (
                            <CopyToClipboard text={String(item.text)} />
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
            <Col>
              <Title level={4}>Detalhes do Pedido</Title>
              {record?.summary?.table?.order?.products &&
                record.summary.table.order.products.map(item => (
                  <Row key={item.item_name}>
                    <div
                      className="border rounded-lg p-4 mb-2"
                      style={{ backgroundColor: '#FCFCFD', width: '100%' }}
                    >
                      <Text>{item.item_name}</Text>
                    </div>
                  </Row>
                ))}
              <div className="border rounded-lg p-2 mb-2">
                <Row>
                  {record?.summary &&
                    orderHeadTags(record?.summary).map(item => (
                      <Col key={item.title} md={4} style={{ margin: 10 }}>
                        <Title level={5}>{item.title}</Title>

                        <div className="flex gap-2">
                          <Text>{item.text || '-'}</Text>
                          {item.allowCopy && item.text && (
                            <CopyToClipboard text={String(item.text)} />
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
            <Col>
              <Stack
                css={{
                  w: '$full',
                  gap: '$4',
                  flexWrap: 'wrap',
                  '@lg': { flexWrap: 'nowrap' }
                }}
              >
                <Accordion
                  css={{ w: '$full', h: 'fit-content' }}
                  type="multiple"
                >
                  {record?.details
                    ?.filter(
                      pp =>
                        pp.from_deal ||
                        pp.invoices.find(
                          inv => inv.index === 0 && inv.status === 'paid'
                        )
                    )
                    ?.sort(
                      (a, b) => new Date(a.created_at) - new Date(b.created_at)
                    )
                    ?.map((plan, index) => (
                      <PaymentPlanItem
                        key={index.toString()}
                        plan={plan}
                        index={index.toString()}
                        orderId={id}
                      />
                    ))}
                </Accordion>
              </Stack>
            </Col>
          </Space>
        )}
      </Show>
    </>
  );
};

const PaymentPlanItem = ({
  plan,
  orderId,
  index
}: {
  plan: IOrderPaymentPlansDetail;
  orderId: string;
  index: string;
}) => {
  const { show: showModal, modalProps, close: closeModal } = useModal();

  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  const planName = `Plano de pagamento ${index + 1} - ${
    plan.from_deal ? 'Renegociaçao' : 'Principal'
  }`;

  const handlePlanCancelation = () => {
    mutate(
      {
        url: `${apiUrl}/orders/${orderId}/payment_plans/cancel`,
        method: 'post',
        values: {
          payment_plan_ids: [plan.id]
        },
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message: 'As cobranças foram interrompidas',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );
  };

  return (
    <AccordionItem key={plan.id} value={plan.id}>
      <Stack
        css={{
          flexCenter: true,
          pb: '$2',
          flexWrap: 'wrap',
          w: '$full',
          '> h3': {
            w: '$full'
          },
          '> h3 > button': {
            w: 'fit-content'
          },
          '@md': {
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            w: '$full',
            pr: '$3',
            pb: 'unset'
          }
        }}
      >
        <AccordionTrigger
          hideIcon
          css={{
            w: '$full',
            '@hover': { '&:hover': { bgColor: 'transparent' } }
          }}
        >
          <Stack css={{ gap: '$4', flexCenter: true }}>
            <Badge
              css={{
                minMaxSize: 28,
                bgColor: '$primary50',
                color: '$primary500',
                transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
                '[data-state=open] > &': {
                  transform: 'rotate(180deg)'
                },
                '@dark': {
                  bgColor: '$primary900',
                  color: '$primary400'
                }
              }}
              aria-hidden
            >
              <Icon icon={faChevronDown} />
            </Badge>
            <Text>{planName}</Text>
            <Badge
              variant={plan?.status === 'cancelled' ? 'warning' : 'success'}
            >
              {paymentPlanStatus(plan?.status)}
            </Badge>
          </Stack>
        </AccordionTrigger>
        {plan.status === 'created' && (
          <Button variant="neutral" onClick={() => showModal()} size="sm">
            Interromper cobranças
          </Button>
        )}
      </Stack>
      <AccordionContent css={{ w: '$full' }}>
        <PlanContentTable orderId={orderId} plan={plan} />
      </AccordionContent>
      <ConfirmationModal
        closable={false}
        onOk={handlePlanCancelation}
        okType="danger"
        okText={modalCopy.okText}
        bodyTitle={modalCopy.bodyTitle}
        bodyText={modalCopy.bodyText}
        width={400}
        {...modalProps}
      />
    </AccordionItem>
  );
};

const modalCopy = {
  okText: 'Sim, quero interromper',
  bodyTitle: 'Tem certeza que quer interromper as próximas cobranças?',
  bodyText:
    'Ao confirmar, você estará interrompendo todas as cobranças futuras desde plano de pagamento'
};
