import 'react-quill/dist/quill.snow.css';
import * as Icons from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { Button, Form, Radio, Select } from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { textValidator } from 'utils/validators';

export const AssessmentQuestionCreate = () => {
  const { formProps, saveButtonProps } = useForm<IAssessment>({
    redirect: 'edit',
    errorNotification: (error, values) => {
      const question = values as IAssessmentQuestion;

      if (!question.correct_alternative_id) {
        return {
          description: 'Alternativa correta',
          message: 'É necessário informar uma alterantiva correta',
          type: 'error'
        };
      }

      return {
        description: 'Ops! Algo de errado',
        message: 'Não foi possível criar a questão. Tente novamente',
        type: 'error'
      };
    }
  });

  const { selectProps: assessmentsQueryProps } = useSelect<IAssessment>({
    resource: 'assessments',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="assessment_id"
          label="Simulado"
          required
          rules={[
            {
              required: true,
              message: 'É necessário selecionar um simulado'
            }
          ]}
        >
          <Select {...assessmentsQueryProps} />
        </Form.Item>
        <div className="flex gap-5 w-full">
          <div className="grow">
            <Form.Item
              name={['statement']}
              label="Enunciado da questão"
              required
              rules={[
                {
                  validator: (_, value, cb) =>
                    textValidator(
                      value,
                      cb,
                      'Deve conter no mínimo 10 caracteres',
                      10
                    )
                }
              ]}
            >
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item name={['feedback']} label="Gabarito">
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item name={['correct_alternative']}>
              <Radio.Group>
                <Form.List name={['alternatives']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((fieldAlternative, index) => (
                        <div
                          key={fieldAlternative.key}
                          className="flex gap-5 w-full"
                        >
                          <Radio value={fieldAlternative.name}></Radio>
                          <div className="grow">
                            <Form.Item
                              name={[fieldAlternative.name, 'statement']}
                              label={`Alternativa ${index + 1}`}
                              required
                              rules={[
                                {
                                  validator: (_, value, cb) =>
                                    textValidator(
                                      value,
                                      cb,
                                      'Campo obrigatório'
                                    )
                                }
                              ]}
                            >
                              <ReactQuill theme="snow" className="error" />
                            </Form.Item>
                          </div>
                          <Button
                            size="small"
                            onClick={() => remove(fieldAlternative.name)}
                          >
                            <Icons.MinusCircleOutlined />
                          </Button>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        className="!flex items-center justify-center"
                        onClick={() => add()}
                        block
                      >
                        <Icons.PlusOutlined />
                        Add Alternativa
                      </Button>
                    </>
                  )}
                </Form.List>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Create>
  );
};
