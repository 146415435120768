import { OrderPaymentPlanDeal } from 'pages/ecommerce/orders/payment_plans/deal';
import { OrderPaymentPlanShow } from 'pages/ecommerce/orders/payment_plans';
import { OrderPaymentPlansList } from 'pages/ecommerce/orders/payment_plans/list';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'payment_plans',
  label: 'Busca',
  list: OrderPaymentPlansList,
  show: OrderPaymentPlanShow,
  create: OrderPaymentPlanDeal,
  actions: ['list', 'show', 'create', 'deal', 'cancel', 'interrupt'],
  parentName: '_deal'
};

export default generateResources(resource);
