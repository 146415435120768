import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { IAssessment } from 'interfaces/assessments';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';

dayjs.extend(duration);

export const AssessmentEdit = () => {
  const { form, formProps, saveButtonProps, queryResult } =
    useForm<IAssessment>({
      redirect: 'edit'
    });

  const assessment = queryResult?.data?.data;

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: assessment?.parent_id,

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: assessment?.parent_id,

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const assessmentType = form.getFieldValue('parent_type');

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar Avaliação">
      <Form
        form={form}
        {...formProps}
        layout="vertical"
        initialValues={{
          ...assessment,
          time_in_minutes: Number(assessment?.time_in_seconds) / 60
        }}
      >
        <Form.Item name="parent_type" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="Nome" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item
          label={
            assessmentType === 'Graduate::Discipline' ? 'Disciplina' : 'Curso'
          }
          name="parent_id"
          required
        >
          {assessmentType === 'Graduate::Discipline' ? (
            <Select {...disciplineSelectProps} disabled={true} />
          ) : (
            <Select {...courseSelectProps} disabled={true} />
          )}
        </Form.Item>
        <Row gutter={40}>
          <Col>
            <Form.Item
              label="Tentativas"
              name="max_attempts"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              hidden
              name="time_in_seconds"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Duração"
              name="time_in_minutes"
              initialValue={form.getFieldValue('time_in_seconds') / 60}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                addonAfter="min"
                min={0}
                className="max-w-[120px]"
                onChange={value =>
                  form.setFieldsValue({ time_in_seconds: Number(value) * 60 })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Quantidade de questões"
              tooltip="É necessário ter no mínimo essa quantidade de questões para gerar a prova."
              name="question_amount"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col>
            <Form.Item
              label="Peso"
              name="weight"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Nota mínima (0 - 100)"
              name="passing_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
