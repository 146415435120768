import { auditIcon } from '../assets/icons/audit';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'audit',
  label: 'Auditoria',
  icon: auditIcon
};

export default generateResources(resource);
