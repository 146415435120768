import * as Icons from '@ant-design/icons';
import { Button } from '@qcx/ui';
import { IAttributeField } from 'interfaces/documentation/document-attribute-field';
import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { Modal, Typography } from 'antd';
import { useModalReturnType } from '@refinedev/core';

interface DocumentationInfoModalProps {
  modal: useModalReturnType;
  documentTemplate?: IDocumentTemplate;
}

const DocumentationInfoModal = ({
  modal,
  documentTemplate
}: DocumentationInfoModalProps) => {
  if (!documentTemplate) {
    return null;
  }
  return (
    <Modal
      title={'Informações sobre a documentação'}
      width={400}
      visible={modal.visible}
      onCancel={modal.close}
      footer={null}
      closeIcon={<Icons.CloseOutlined />}
    >
      <Typography.Paragraph>
        Além de anexar o documento, o aluno deverá preencher:
      </Typography.Paragraph>
      <ul className="list-disc ml-4">
        {documentTemplate.attribute_fields?.map(
          (attribute: IAttributeField) => (
            <li key={attribute.name}>
              <Typography.Paragraph style={{ margin: 0 }}>
                {attribute.name}
              </Typography.Paragraph>
            </li>
          )
        )}
      </ul>
      <Button className="w-full" onClick={() => modal.close()}>
        Entendi
      </Button>
    </Modal>
  );
};

export default DocumentationInfoModal;
