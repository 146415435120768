import { ICourseRequirement } from 'interfaces/courses';
import { IGraduateCourseRequirement } from 'interfaces/graduate_courses';
import { getAPI } from 'requests/api';

const api = getAPI();

interface CreateProps {
  courseId: string;
  nodeId: string;
  duration: number | string;
  key: 'possession_date' | 'progress_percentage' | 'current_date';
  resourceType?: // eslint-disable-next-line prettier/prettier
  | 'graduate_module'
  | 'graduate_discipline'
  | 'assessment'
  | 'assignment';
  kind: 'duration_minutes' | 'date';
}

interface UpdateProps {
  requirementId: string;
  courseId: string;
  nodeId: string;
  duration: number | string;
  key: 'possession_date' | 'progress_percentage' | 'current_date';
  resourceType?: // eslint-disable-next-line prettier/prettier
  | 'graduate_module'
  | 'graduate_discipline'
  | 'assessment'
  | 'assignment';
  kind: 'duration_minutes' | 'date';
}

interface CreateGraduateRequirementProps extends Omit<CreateProps, 'nodeId'> {
  resourceId: string;
}

interface UpdateGraduateRequirementProps extends Omit<UpdateProps, 'nodeId'> {
  resourceId: string;
}

export const createRequirement = async ({
  courseId,
  nodeId,
  duration,
  key,
  resourceType,
  kind
}: CreateProps): Promise<ICourseRequirement> => {
  const { data } = await api.post(`/admin/course_requirements`, {
    course_id: courseId,
    resource_kind: resourceType ?? 'tree_node',
    resource_id: nodeId,
    rules: [
      {
        kind,
        key,
        operator: 'after',
        value: kind === 'date' ? String(duration) : Number(duration)
      }
    ]
  });

  return data;
};

export const updateRequirement = async ({
  requirementId,
  courseId,
  nodeId,
  duration,
  key,
  resourceType,
  kind
}: UpdateProps): Promise<ICourseRequirement> => {
  const { data } = await api.put(
    `/admin/course_requirements/${requirementId}`,
    {
      course_id: courseId,
      resource_kind: resourceType ?? 'tree_node',
      resource_id: nodeId,
      rules: [
        {
          kind,
          operator: 'after',
          key,
          value: kind === 'date' ? String(duration) : Number(duration)
        }
      ]
    }
  );

  return data;
};

export const createModuleRequirement = async ({
  courseId,
  resourceId,
  duration,
  key,
  resourceType,
  kind
}: CreateGraduateRequirementProps): Promise<IGraduateCourseRequirement> => {
  const { data } = await api.post(`/admin/graduate_course_requirements`, {
    course_id: courseId,
    resource_kind: resourceType ?? 'graduate_module',
    resource_id: resourceId,
    rules: [
      {
        kind,
        operator: 'after',
        key,
        value: Number(duration)
      }
    ]
  });

  return data;
};

export const updateModuleRequirement = async ({
  requirementId,
  courseId,
  resourceId,
  duration,
  key,
  resourceType,
  kind
}: UpdateGraduateRequirementProps): Promise<IGraduateCourseRequirement> => {
  const { data } = await api.put(
    `/admin/graduate_course_requirements/${requirementId}`,
    {
      course_id: courseId,
      resource_kind: resourceType ?? 'graduate_module',
      resource_id: resourceId,
      rules: [
        {
          kind,
          operator: 'after',
          key,
          value: kind === 'date' ? duration : Number(duration)
        }
      ]
    }
  );

  return data;
};
