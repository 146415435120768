import * as Icons from '@ant-design/icons';
import React from 'react';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { TextField } from '@refinedev/antd';
import { evaluationMap } from './Evaluation';
import { parseToDayjs } from 'services/date';

export interface EvaluationsHeaderProps {
  evaluationData: IClassroomEvaluation;
}

const EvaluationsHeader = ({ evaluationData }: EvaluationsHeaderProps) => {
  const getRangeDate = (type: 'start' | 'end') => {
    const { requirements } = evaluationData;
    const operator = type === 'start' ? 'after' : 'before';
    const date = requirements?.[0].rules.find(
      element => element.operator === operator
    )?.value;

    return date ? parseToDayjs(String(date), 'DD/MM/YYYY') : null;
  };

  return (
    <>
      {Array.from({ length: 5 })
        .fill(null)
        // eslint-disable-next-line sonarjs/cognitive-complexity
        .map((_, index) => (
          <div key={index}>
            <span className="text-gray-700 text-14 font-Inter font-light">
              {index === 0 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <Icons.UnorderedListOutlined className="text-[20px]" />
                  <TextField
                    value={`Tipo: ${
                      evaluationMap[
                        evaluationData.evaluation_type as keyof typeof evaluationMap
                      ]
                    }`}
                  />
                </div>
              ) : index === 1 && evaluationData.kind !== 'final' ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <Icons.CalculatorOutlined className="text-[20px]" />
                  <TextField value={`Peso: ${evaluationData.weight}`} />
                </div>
              ) : index === 2 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <Icons.FileDoneOutlined className="text-[20px]" />
                  <TextField
                    value={`Nota mínima: ${evaluationData.passing_score}`}
                  />
                </div>
              ) : index === 3 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <Icons.ClockCircleOutlined className="text-[20px]" />
                  <TextField
                    value={`Tentativas: ${evaluationData.max_attempts}`}
                  />
                </div>
              ) : index === 4 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <Icons.CalendarOutlined className="text-[20px]" />
                  <TextField
                    value={`Acesso: ${getRangeDate('start')} - ${getRangeDate(
                      'end'
                    )}`}
                  />
                </div>
              ) : null}
            </span>
          </div>
        ))}
    </>
  );
};

export default EvaluationsHeader;
