import DesactivateQuestionModal from 'components/modal/question-assignment/DesactivateQuestionModal';
import NullifyQuestionModal from 'components/modal/question-assignment/NullifyQuestionModal';
import ReactQuill from 'react-quill';
import {
  Button,
  Dropdown,
  Form,
  MenuProps,
  Radio,
  Select,
  Space,
  Typography
} from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Edit,
  ListButton,
  RefreshButton,
  TagField,
  useForm,
  useModal,
  useSelect
} from '@refinedev/antd';
import {
  HttpError,
  ResourceRouterParams,
  useCreate,
  useParsed,
  useResource
} from '@refinedev/core';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { MenuInfo } from 'rc-menu/lib/interface';
import { QUESTION_STATE_MAP } from 'services/assessments';
import { QuestionLogModal } from 'components/modal/question-assignment/QuestionLogModal';
import { textValidator } from 'utils/validators';
import { useCallback, useState } from 'react';

const actionTypeMap = {
  activate: {
    title: 'Ativar questão?',
    description:
      'Esta questão poderá ser utilizada para criação de avaliações e provas!'
  },
  deactivate: {
    title: 'Desativar questão?',
    description:
      'Esta questão não poderá mais ser utilizada para criação de avaliações e provas!'
  },
  nullify: {
    title: 'Desativar anular essa questão?',
    description:
      'Essa ação não poderá ser desfeita. O ponto será adicionado à nota dos alunos que realizaram avaliações com essa questão.'
  }
};

export const AssessmentQuestionEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<
    IAssessmentQuestion,
    HttpError,
    IAssessmentQuestion
  >({
    redirect: 'edit',
    errorNotification: (error, values) => {
      const question = values as IAssessmentQuestion;

      if (!question.correct_alternative_id) {
        return {
          description: 'Alternativa correta',
          message: 'É necessário informar uma alterantiva correta',
          type: 'error'
        };
      }

      return {
        description: 'Ops! Algo de errado',
        message: 'Não foi possível editar a questão. Tente novamente',
        type: 'error'
      };
    }
  });
  const { params } = useParsed();
  const { resource: routeResourceName, id: idFromRoute } =
    params as ResourceRouterParams;
  const { resource } = useResource(routeResourceName);

  const question = queryResult?.data?.data;
  const assessmentName = question?.assessment_name;
  const filterValue = assessmentName?.slice(assessmentName?.indexOf('|') + 2);
  const [actionType, setActionType] = useState<'activate' | 'deactivate'>(
    'activate'
  );
  const { show, modalProps, close } = useModal();
  const { show: showLogModal, modalProps: logModalProps } = useModal();
  const {
    show: nullifyModalShow,
    modalProps: nullifyModalProps,
    close: nullifyModalClose
  } = useModal();
  const { mutate: mutateAction } = useCreate();

  const { selectProps: assessmentsQueryProps } = useSelect<IAssessment>({
    resource: 'assessments',
    optionLabel: 'name',
    optionValue: 'id',

    filters: [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value: filterValue
      }
    ],

    onSearch: value => [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const handleDeleteDialog = useCallback(
    (type: 'activate' | 'deactivate') => {
      setActionType(type);
      return show();
    },
    [show]
  );

  const handleModalAction = () => {
    mutateAction(
      {
        resource: `assessment_questions/${question?.id}/${actionType}`,
        values: {},
        errorNotification: () => {
          return {
            message: 'Ocorreu um problema ao tentar alterar o status!',
            description: 'Algo deu errado!',
            type: 'error'
          };
        },
        successNotification: () => {
          return {
            message: `Status da questão alterada com sucesso!`,
            description: 'Tudo certo!',
            type: 'success'
          };
        }
      },

      {
        onSuccess: () => {
          queryResult?.refetch();
        }
      }
    );
    close();
  };

  const items: MenuProps['items'] = [
    {
      label: 'Histórico de alterações',
      icon: <ClockCircleOutlined />,
      key: '1'
    },
    {
      label: `${
        question?.status === 'active' ? 'Desativar questão' : 'Ativar questão'
      }`,
      disabled: question?.status === 'nullified',
      icon:
        question?.status === 'active' ? (
          <WarningOutlined />
        ) : (
          <CheckCircleOutlined />
        ),
      danger: question?.status === 'active',
      key: '2'
    },
    {
      label: 'Anular questão',
      icon: <CloseCircleOutlined />,
      danger: true,
      key: '3',
      disabled: !question?.nullable
    }
  ];

  const handleMenuClick = useCallback(
    (event: MenuInfo) => {
      if (event.key === '1') {
        showLogModal();
      }

      if (event.key === '2') {
        return question?.status === 'active'
          ? handleDeleteDialog('deactivate')
          : handleDeleteDialog('activate');
      }

      if (event.key === '3') {
        nullifyModalShow();
      }
    },
    [handleDeleteDialog, nullifyModalShow, showLogModal, question]
  );

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  if (!question) {
    return null;
  }

  return (
    <Edit
      saveButtonProps={{
        ...saveButtonProps,
        disabled: question?.status === 'nullified'
      }}
      headerButtons={
        <>
          <ListButton resource={resource.identifier} />
          <RefreshButton
            resource={resource.identifier}
            recordItemId={idFromRoute}
          />
          <Dropdown menu={menuProps} arrow>
            <Button type="primary" className="!shadow-none">
              <Space>
                Ações
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </>
      }
    >
      <Space className="mb-4">
        <Typography.Paragraph style={{ margin: 0 }}>
          Status
        </Typography.Paragraph>
        <TagField
          value={QUESTION_STATE_MAP[question?.status].label}
          color={QUESTION_STATE_MAP[question?.status].color}
        />
      </Space>
      <Form
        {...formProps}
        layout="vertical"
        disabled={question.status === 'nullified'}
      >
        <Form.Item name="assessment_id" label="Simulado">
          <Select
            {...assessmentsQueryProps}
            disabled={question.status !== 'active'}
          />
        </Form.Item>
        <div className="flex gap-5 w-full">
          <div className="grow">
            <Form.Item
              name={['statement']}
              label="Enunciado da questão"
              required
              rules={[
                {
                  validator: (_, value, cb) =>
                    textValidator(
                      value,
                      cb,
                      'Deve conter no mínimo 10 caracteres',
                      10
                    )
                }
              ]}
            >
              <ReactQuill
                theme="snow"
                readOnly={question.status === 'nullified'}
                style={{
                  color: question.status === 'nullified' ? 'gray' : 'unset'
                }}
              />
            </Form.Item>
            <Form.Item name={['feedback']} label="Gabarito">
              <ReactQuill
                theme="snow"
                readOnly={question.status === 'nullified'}
                style={{
                  color: question.status === 'nullified' ? 'gray' : 'unset'
                }}
              />
            </Form.Item>
            <Form.Item name={['correct_alternative_id']}>
              <Radio.Group>
                <Form.List name={['alternatives']}>
                  {fields => (
                    <>
                      {fields.map((fieldAlternative, index) => (
                        <div
                          key={fieldAlternative.key}
                          className="flex gap-5 w-full"
                        >
                          <Radio
                            value={
                              question?.alternatives[fieldAlternative.name].id
                            }
                          />
                          <div className="grow">
                            <Form.Item
                              label={`Alternativa ${index + 1}`}
                              name={[fieldAlternative.name, 'statement']}
                              required
                              rules={[
                                {
                                  validator: (_, value, cb) =>
                                    textValidator(
                                      value,
                                      cb,
                                      'Campo obrigatório'
                                    )
                                }
                              ]}
                            >
                              <ReactQuill
                                theme="snow"
                                style={{
                                  color:
                                    question.status === 'nullified'
                                      ? 'gray'
                                      : 'unset'
                                }}
                                readOnly={question.status === 'nullified'}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </Form>
      <DesactivateQuestionModal
        closable={false}
        okText="Confirmar"
        onOk={handleModalAction}
        okButtonProps={{ danger: question?.status === 'active' }}
        bodyTitle={`${actionTypeMap[actionType].title}`}
        bodyText={`${actionTypeMap[actionType].description}`}
        width={500}
        {...modalProps}
      />
      <NullifyQuestionModal
        closable={false}
        okText="Confirmar"
        onOk={handleModalAction}
        onClose={nullifyModalClose}
        refetch={queryResult?.refetch}
        questionId={question.id}
        okButtonProps={{ danger: true }}
        bodyTitle={`${actionTypeMap['nullify'].title}`}
        bodyText={`${actionTypeMap['nullify'].description}`}
        width={500}
        {...nullifyModalProps}
      />
      {logModalProps.open && (
        <QuestionLogModal modalProps={logModalProps} questionId={question.id} />
      )}
    </Edit>
  );
};
