import AssetUpload from 'components/AssetUpload';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IOfferCampaignBase, TOfferCampaignAsset } from 'interfaces/offers';
import { STATUSES, translateStatus } from 'services/offers';
import { UploadFile } from 'antd/lib/upload/interface';

const onValidate = async (file: UploadFile, minW: number): Promise<boolean> => {
  let isValid = false;
  const img = new Image();
  img.src = window.URL?.createObjectURL(file as unknown as File);

  const loadImage = () => {
    return new Promise(resolve => {
      /* eslint-disable-next-line unicorn/prefer-add-event-listener */
      img.onload = () => {
        isValid = img.width >= minW;
        resolve(true);
      };
    });
  };

  await loadImage();

  return isValid;
};

export const mobileValidation = async (file: UploadFile) => {
  return await onValidate(file, 992);
};

export const desktopValidation = async (file: UploadFile) => {
  return await onValidate(file, 1440);
};

export const OfferCampaignAssetCreate = () => {
  const { formProps, saveButtonProps } = useForm<TOfferCampaignAsset>({
    resource: 'offers/campaigns/assets',
    redirect: false
  });

  const { selectProps: campaignsSelectProps } = useSelect<IOfferCampaignBase>({
    resource: 'offer_campaigns',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar banner">
      <Form
        {...formProps}
        initialValues={{ status: 'disabled', asset_type: 'hero_banner' }}
        layout="vertical"
      >
        <Form.Item
          style={{ display: 'none' }}
          label="Tipo"
          name="asset_type"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input value="hero_banner" />
        </Form.Item>
        <Form.Item
          label="Redirecionar para"
          name="href"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder="ex: www.google.com.br" />
        </Form.Item>
        <Form.Item
          label="Alt text (acessibilidade)"
          name="alt_text"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder="Todos os cursos por R$59,90, clique para acessar" />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select disabled>
            {STATUSES.map(status => (
              <Select.Option key={status}>
                {translateStatus(status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Versão desktop"
          tooltip="Largura mínima: 1440px"
          name="desktop_url"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload
            accept="image/jpeg"
            type="hero_banner"
            onValidate={desktopValidation}
          />
        </Form.Item>
        <Form.Item
          label="Versão mobile"
          tooltip="Largura mínima: 992px"
          name="mobile_url"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload
            accept="image/jpeg"
            type="hero_banner"
            onValidate={mobileValidation}
          />
        </Form.Item>
        <Form.Item
          label="Campanha relacionada (opcional)"
          name="campaign_id"
          style={{ marginTop: '12px' }}
        >
          <Select {...campaignsSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
