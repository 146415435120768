import * as Icons from '@ant-design/icons';
import AssociationForm from './components/AssociationForm';
import AssociationsTable from './components/AssociationsTables';
import DocumentationAssociationModal from 'components/modal/documents/DocumentationAssociationModal';
import routerProvider from '@refinedev/react-router-v6/legacy';
import { Alert, Button, Col, Divider, Row, Space, Tag, Typography } from 'antd';
import {
  IDocumentTemplate,
  IDocumentTemplateCategory,
  IDocumentTemplateCourse
} from 'interfaces/documentation/document-template';
import { List } from '@refinedev/antd';
import { useList, useModal, useOne } from '@refinedev/core';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const CATEGORY_MAP: Record<IDocumentTemplateCategory, string> = {
  academic: 'Documentação acadêmica',
  identification: 'Documentação de identificação',
  supplementary: 'Documentação complementar'
};

const DocumentationAssociation = () => {
  const templateCourseResource = 'documents/template_courses';
  const [associationContext, setAssociationContext] =
    useState<IDocumentTemplateCourse>();
  const modal = useModal();
  const [searchParams, _] = useSearchParams();

  const { data: template } = useOne<IDocumentTemplate>({
    resource: 'documents/templates',
    id: searchParams.get('record') || ''
  });

  const { data: templateCourses } = useList<IDocumentTemplateCourse>({
    resource: templateCourseResource,

    filters: [
      {
        field: 'filter[by_template_id]',
        operator: 'eq',
        value: template?.data?.id
      }
    ]
  });

  if (!template?.data) {
    return null;
  }
  const attributeFields = template.data.attribute_fields || [];
  const { Link } = routerProvider;
  const extra = (
    <Link to="/academic/documentations?tab=documentTypes">
      <Button type="default" icon={<Icons.UnorderedListOutlined />}>
        Tipos de documentos
      </Button>
    </Link>
  );
  return (
    <List
      title="Associação de documentação"
      headerButtons={[<div key="extra">{extra}</div>]}
    >
      <Divider style={{ marginTop: '0px' }} />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>
          {' '}
          Informações sobre a documentação{' '}
        </Typography.Title>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <p>
              <strong>Nome:</strong> {template.data.name}
            </p>
            <p>
              <strong>Categoria:</strong> {CATEGORY_MAP[template.data.category]}
            </p>
          </Col>
          <Col span={14}>
            <Typography.Paragraph>
              Além de anexar o documento, o aluno deverá preencher:
            </Typography.Paragraph>
            <Row gutter={[16, 24]} style={{ marginLeft: '0px' }}>
              {attributeFields.map(attribute => (
                <Tag key={attribute.name}>{attribute.name}</Tag>
              ))}
            </Row>
          </Col>
        </Row>
      </Space>

      <Alert
        description="Esta documentação será aplicável a todos os cursos inseridos e listados abaixo."
        type="info"
        showIcon
        style={{ padding: '6px 6px 6px 16px' }}
      />

      <Divider />
      <Typography.Title level={4}>Associar Curso</Typography.Title>

      <AssociationForm
        name={template.data.name}
        templateId={searchParams.get('record')}
        resource={templateCourseResource}
      />
      <Divider />
      <Typography.Title level={4}>Cursos Associados</Typography.Title>

      <AssociationsTable
        resource={templateCourseResource}
        modal={modal}
        setAssociationContext={setAssociationContext}
        templateCourses={templateCourses?.data}
      />
      <DocumentationAssociationModal
        modal={modal}
        associationCouse={associationContext}
        resource={templateCourseResource}
      />
    </List>
  );
};

export default DocumentationAssociation;
