import { CrudFilters, HttpError } from '@refinedev/core';
import { INoticeBoard } from 'interfaces/notice_board';
import {
  INoticeBoardFilterForm,
  NoticeBoardListFilter
} from './components/ListFilter';
import { List, useTable } from '@refinedev/antd';
import { ListTable } from './components/ListTable';

export const translateKind = (kind: string) => {
  switch (kind) {
    case 'general': {
      return 'Todos';
    }

    case 'graduate_course': {
      return 'Curso regulado';
    }

    case 'classroom': {
      return 'Turma';
    }

    default: {
      return kind;
    }
  }
};

export const translatePriority = (priority: string) => {
  switch (priority) {
    case 'regular': {
      return 'Normal';
    }

    case 'high': {
      return 'Alta';
    }

    default: {
      return priority;
    }
  }
};

export const NoticeBoardList = () => {
  const { tableProps, searchFormProps } = useTable<
    INoticeBoard,
    HttpError,
    INoticeBoardFilterForm
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { title, kind, priority } = params;

      filters.push(
        {
          field: 'filter[by_kind]',
          operator: 'eq',
          value: kind
        },
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[by_priority]',
          operator: 'eq',
          value: priority
        }
      );

      return filters;
    }
  });
  return (
    <List>
      <NoticeBoardListFilter searchFormProps={searchFormProps} />
      <ListTable tableProps={tableProps} />
    </List>
  );
};
