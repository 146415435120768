import CreateAudioModal from './components/modals/CreateAudioModal';
import CreateDocumentModal from './components/modals/CreateDocumentModal';
import CreateVideoModal from './components/modals/CreateVideoModal';
import {
  AudioOutlined,
  DownOutlined,
  FileTextOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Typography
} from 'antd';
import {
  Edit,
  ShowButton,
  TextField,
  useForm,
  useTable
} from '@refinedev/antd';
import { HttpError, useModal } from '@refinedev/core';
import { ILibrary, ILibraryContent } from 'interfaces/libraries';
import { MenuProps } from 'antd/lib';
import { translateKind } from '../../services/libraries/contents';
import { useParams } from 'react-router-dom';

export const LibrariesEdit = () => {
  const { id } = useParams();
  const createDocumentModal = useModal();
  const createAudioModal = useModal();
  const createVideoModal = useModal();

  const { formProps, saveButtonProps } = useForm<ILibrary>({
    resource: 'prometheus/v2/libraries',
    id,
    action: 'edit',
    redirect: false,
    autoSave: {
      enabled: true,
      debounce: 1000
    }
  });

  const { tableProps, tableQueryResult } = useTable<
    ILibraryContent,
    HttpError,
    { byName: string }
  >({
    resource: `prometheus/v2/libraries/${id}/contents`,
    onSearch: ({ byName }) => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  const onFinish = () => {
    tableQueryResult.refetch();
  };

  const handleMenuClick: MenuProps['onClick'] = e => {
    const modals = [createDocumentModal, createAudioModal, createVideoModal];

    for (const modal of modals) {
      modal.close();
    }

    switch (e.key) {
      case 'document': {
        createDocumentModal.show();

        break;
      }

      case 'audio': {
        createAudioModal.show();

        break;
      }

      case 'video': {
        createVideoModal.show();

        break;
      }
      // No default
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Áudio',
      key: 'audio',
      icon: <AudioOutlined />
    },
    {
      label: 'Vídeo',
      key: 'video',
      icon: <VideoCameraOutlined />
    },
    {
      label: 'Documento',
      key: 'document',
      icon: <FileTextOutlined />
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <Edit
      title="Editar biblioteca"
      headerButtons={() => (
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Adicionar conteúdo
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}
      saveButtonProps={{ ...saveButtonProps, hidden: true }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Typography.Title level={3}>Conteúdos</Typography.Title>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="kind"
          key="kind"
          title="Tipo"
          render={value => <TextField value={translateKind(value)} />}
        />

        <Table.Column
          dataIndex="authors"
          key="authors"
          title="Autores"
          render={value =>
            value.map((author: string) => <Tag key={author}>{author}</Tag>)
          }
        />
        <Table.Column<ILibrary>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  resource="library_contents"
                  recordItemId={record.id}
                  meta={{ library_id: id }}
                />
              </Space>
            );
          }}
        />
      </Table>

      <CreateDocumentModal modal={createDocumentModal} onFinish={onFinish} />
      <CreateAudioModal modal={createAudioModal} onFinish={onFinish} />
      <CreateVideoModal modal={createVideoModal} onFinish={onFinish} />
    </Edit>
  );
};
