import '@refinedev/antd/dist/reset.css';
import accessProvider from './access-provider';
import authProvider from './providers/auth-provider';
import dataProvider from './providers/data-provider';
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource
} from '@refinedev/react-router-v6';
import { Authenticated, I18nProvider, Refine } from '@refinedev/core';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ErrorComponent, useNotificationProvider } from '@refinedev/antd';
import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider
} from 'components/layout';
import { GoogleLogin } from 'pages/login/google';
import { IAppResource } from 'interfaces/resources';
import { LoginPage } from 'pages/login';
import { getAPI } from 'requests/api';
import { getResourcesAndRoutes } from 'resources';
import { getTenant } from 'services/tenants';
import { themeConfig } from 'configs/antd';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  };

  return (
    <BrowserRouter>
      <ConfigProvider theme={themeConfig}>
        <Refine
          options={{ liveMode: 'auto', disableTelemetry: true }}
          notificationProvider={useNotificationProvider}
          accessControlProvider={accessProvider}
          i18nProvider={i18nProvider}
          routerProvider={routerProvider}
          dataProvider={dataProvider(`${getTenant().apiUrl}/admin`, getAPI())}
          authProvider={authProvider}
          resources={getResourcesAndRoutes().resource as IAppResource[]}
        >
          <Routes>
            <Route
              element={
                <Authenticated
                  key="authenticated-layout"
                  fallback={<CatchAllNavigate to="/login" />}
                >
                  <Layout Sider={Sider} Header={Header} Footer={Footer}>
                    <Outlet />
                  </Layout>
                </Authenticated>
              }
            >
              {getResourcesAndRoutes().routes}
              <Route path="*" element={<ErrorComponent />} />
            </Route>
            <Route
              element={
                <Authenticated key="authenticated-auth" fallback={<Outlet />}>
                  <NavigateToResource resource="users" />
                </Authenticated>
              }
            >
              <Route path="/login" element={<LoginPage />} />
              <Route path="/oauth/google" element={<GoogleLogin />} />
            </Route>
          </Routes>
          <OffLayoutArea />
        </Refine>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
