import { getTenant } from 'services/tenants';

const tenant = getTenant();
const prometheusDisabled = tenant.disabledFeatures?.includes('prometheus');

export interface IProviderOption {
  readonly value: string | undefined;
  readonly label: string | undefined;
  readonly disabled: boolean | undefined;
}

export const PROVIDER_OPTIONS = {
  video: [
    {
      label: 'DAM',
      value: 'prometheus',
      disabled: prometheusDisabled
    },
    {
      label: 'Cloudflare',
      value: 'cloudflare',
      disabled: !prometheusDisabled
    },
    {
      label: 'Cloudflare com Link',
      value: 'cloudflare_with_link',
      disabled: !prometheusDisabled
    }
  ],
  hyper_text: [{ label: 'URL', value: 'url', disabled: false }],
  document: [
    { label: 'URL', value: 'url', disabled: false },
    { label: 'AWS S3', value: 's3', disabled: false }
  ]
};
