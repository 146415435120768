import { ICoreContentOption } from 'interfaces/core_content';
import { IDocumentCreate } from 'interfaces/documentation/document';
import { IHyperTextCreate } from 'interfaces/hyper_texts';
import { IVideoCreate } from 'interfaces/videos';
import { getAPI } from './api';

export const createVideo = (name: string): Promise<IVideoCreate> => {
  const api = getAPI();

  return api.post('admin/videos', { name }).then(resp => resp.data);
};

export const updateVideo = (
  id: string,
  name: string
): Promise<IVideoCreate> => {
  const api = getAPI();

  return api.put(`admin/videos/${id}`, { name }).then(resp => resp.data);
};

export const createVideoOption = (
  video_id: string,
  option: string,
  reference?: string
): Promise<ICoreContentOption> => {
  const api = getAPI();

  return api
    .post(`admin/video_options`, {
      video_id,
      provider: option,
      reference: reference
    })
    .then(resp => resp.data);
};

export const createHyperText = (name: string): Promise<IHyperTextCreate> => {
  const api = getAPI();

  return api.post('admin/hyper_texts', { name }).then(resp => resp.data);
};

export const updateHyperText = (
  id: string,
  name: string
): Promise<IHyperTextCreate> => {
  const api = getAPI();

  return api.put(`admin/hyper_texts/${id}`, { name }).then(resp => resp.data);
};

export const createDocument = (name: string): Promise<IDocumentCreate> => {
  const api = getAPI();

  return api.post('admin/documents', { name }).then(resp => resp.data);
};

export const updateDocument = (
  id: string,
  name: string
): Promise<IDocumentCreate> => {
  const api = getAPI();

  return api.put(`admin/documents/${id}`, { name }).then(resp => resp.data);
};

export const createHyperTextOption = (
  hyper_text_id: string,
  option: string,
  reference?: string
): Promise<ICoreContentOption> => {
  const api = getAPI();

  return api
    .post(`admin/hyper_text_options`, {
      hyper_text_id,
      provider: option,
      reference
    })
    .then(resp => resp.data);
};

export const createDocumentOption = (
  document_id: string,
  option: string,
  reference?: string
): Promise<ICoreContentOption> => {
  const api = getAPI();

  return api
    .post(`admin/document_options`, {
      document_id,
      provider: option,
      reference
    })
    .then(resp => resp.data);
};

export const createPrometheusContent = (
  reference: string,
  name: string
): Promise<ICoreContentOption> => {
  const api = getAPI();

  return api
    .post(`admin/prometheus_contents`, {
      reference,
      name
    })
    .then(resp => resp.data);
};
