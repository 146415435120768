import AssetUpload from 'components/AssetUpload';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IOfferCampaignBase, TOfferCampaignAsset } from 'interfaces/offers';
import { STATUSES, translateStatus } from 'services/offers';
import { desktopValidation, mobileValidation } from './create';
import { useParams } from 'react-router-dom';

export const OfferCampaignAssetEdit = () => {
  const { id } = useParams();

  const { formProps, saveButtonProps, queryResult } =
    useForm<TOfferCampaignAsset>({
      resource: `offers/campaigns/assets`,
      id,
      action: 'edit',
      redirect: false
    });
  const data = queryResult?.data?.data;

  const { selectProps: campaignsSelectProps } = useSelect<IOfferCampaignBase>({
    resource: 'offer_campaigns',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  if (!data) {
    return <Edit saveButtonProps={saveButtonProps} />;
  }

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar banner">
      <Form {...formProps} initialValues={data} layout="vertical">
        <Form.Item
          style={{ display: 'none' }}
          label="Tipo"
          name="asset_type"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input value="hero_banner" />
        </Form.Item>
        <Form.Item
          label="Redirecionar para"
          name="href"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder="ex: www.google.com.br" />
        </Form.Item>
        <Form.Item
          label="Alt text (acessibilidade)"
          name="alt_text"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder="Todos os cursos por R$59,90, clique para acessar" />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select>
            {STATUSES.map(status => (
              <Select.Option key={status}>
                {translateStatus(status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Versão desktop"
          tooltip="Largura mínima: 1440px"
          name="desktop_url"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload
            accept="image/jpeg"
            type="hero_banner"
            src={queryResult?.data?.data?.desktop_url}
            onValidate={desktopValidation}
          />
        </Form.Item>
        <Form.Item
          label="Versão mobile"
          tooltip="Largura mínima: 992px"
          name="mobile_url"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload
            accept="image/jpeg"
            type="hero_banner"
            src={queryResult?.data?.data?.mobile_url}
            onValidate={mobileValidation}
          />
        </Form.Item>
        <Form.Item
          label="Campanha relacionada (opcional)"
          name="campaign_id"
          style={{ marginTop: '12px' }}
        >
          <Select {...campaignsSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
