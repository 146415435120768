import { ACADEMIC_RECORD_STATUSES_MAP } from 'services/graduate-enrollment';
import {
  AcademicHistoryData,
  fetchAcademicHistoryData
} from 'services/graduate-enrollment/academic-history';
import { Empty, Spin, Table, Typography } from 'antd';
import { IAcademicRecord } from 'interfaces/academic_records';
import { Link } from 'react-router-dom';
import { TagField, TextField } from '@refinedev/antd';
import { TitleMap } from 'services/professors';
import { disciplineModulesKind } from 'pages/graduate_courses/components/TabDisciplines';
import { formatDate } from 'services/date';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

interface AcademicHistoryProps {
  courseId: string;
  enrollmentId: string;
}

export const TabAcademicHistory = ({
  courseId,
  enrollmentId
}: AcademicHistoryProps) => {
  const [data, setData] = useState<AcademicHistoryData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { open } = useNotification();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await fetchAcademicHistoryData(courseId, enrollmentId);
      setData(res);
    } catch {
      open?.({
        type: 'error',
        message: 'Erro ao recuperar informações do histórico acadêmico.',
        description: 'Ops!'
      });
    } finally {
      setIsLoading(false);
    }
  }, [courseId, enrollmentId, open]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-16">
        <Spin />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Nenhum histórico acadêmico encontrado"
      />
    );
  }

  return (
    <div>
      <Typography.Text className="block font-semibold mb-4">
        Disciplinas concluídas
      </Typography.Text>

      <Table
        dataSource={data}
        className="w-full"
        size="small"
        style={{ borderRadius: 10, overflow: 'hidden' }}
        pagination={false}
      >
        <Table.Column
          title="Disciplina"
          dataIndex="discipline"
          key="discipline"
          className="w-[300px]"
          render={(value, record: IAcademicRecord) => (
            <TextField
              value={
                <Link
                  to={`/academic/graduate/academic_records/show/${record.id}`}
                >
                  {value?.name}
                </Link>
              }
            />
          )}
        />
        <Table.Column
          title="Tipo"
          dataIndex={['disciplineModule']}
          key="kind"
          render={value => (
            <TextField
              value={
                disciplineModulesKind[
                  value?.kind as keyof typeof disciplineModulesKind
                ]
              }
            />
          )}
        />
        <Table.Column
          title="Professor"
          dataIndex="professor"
          key="name"
          render={value => (
            <>
              {value && (
                <>
                  <TextField value={value?.name} />
                  <TextField
                    type="secondary"
                    value={` (${
                      TitleMap[value?.title as keyof typeof TitleMap]
                    })`}
                  />
                </>
              )}
            </>
          )}
        />
        <Table.Column
          title="Turma"
          dataIndex="parent"
          key="parent"
          render={value => <TextField value={value?.classroom?.code} />}
        />

        <Table.Column
          title="Vigência"
          dataIndex="parent"
          key="parent"
          className="min-w-[140px] max-w-[140px]"
          render={value => (
            <TextField
              value={
                <>
                  {formatDate(value?.classroom?.starts_at, 'DD/MM/YYYY')} -{' '}
                  {formatDate(value?.classroom?.ends_at, 'DD/MM/YYYY')}
                </>
              }
            />
          )}
        />
        <Table.Column
          title="Frequência"
          dataIndex="attendance_rate"
          key="attendance_rate"
          align="center"
          render={value => <TextField value={value ?? 'Sem frequência'} />}
        />
        <Table.Column
          title="Nota"
          dataIndex="score"
          key="score"
          align="center"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          className="w-[80px]"
          render={(value: IAcademicRecord['status']) => (
            <TagField
              color={ACADEMIC_RECORD_STATUSES_MAP[value].color}
              value={ACADEMIC_RECORD_STATUSES_MAP[value].label}
            />
          )}
        />
      </Table>
    </div>
  );
};
