import TagTree from 'components/epimetheus/groups/TagTree';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IGroup } from 'interfaces/epimetheus/groups';

export const GroupEdit = () => {
  const { id, formProps, saveButtonProps } = useForm<IGroup>();

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar gerenciador de tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      <TagTree groupId={id as string} />
    </Edit>
  );
};
