import { Form, Select } from 'antd';
import { IResource } from 'interfaces/epimetheus/resources';
import { ITag } from 'interfaces/epimetheus/tags';
import { useCreate, useUpdate } from '@refinedev/core';
import { useEffect, useState } from 'react';
import { useForm, useSelect } from '@refinedev/antd';

interface Props {
  resource_id?: string;
  resource_name?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onCreateResource: (resource: IResource) => void;
}

const TagSelect = ({
  resource_id,
  resource_name,
  disabled,
  onChange,
  onCreateResource
}: Props) => {
  const { mutate } = useCreate<IResource>();
  const [resourceId, setResourceId] = useState(resource_id);

  const createResource = ({ name }: { name: string }) => {
    return new Promise<IResource>((resolve, reject) => {
      mutate(
        {
          resource: `epimetheus/resources`,
          values: { name: name }
        },
        {
          onSuccess: ({ data }) => {
            onChange?.(data.id!);
            onCreateResource(data);

            resolve(data);
          },
          onError: error => reject(error)
        }
      );
    });
  };

  useEffect(() => {
    if (!resource_id && !resourceId) {
      createResource({
        name: resource_name || 'Resource'
      }).then(resource => {
        setResourceId(resource.id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId]);

  const { formProps, queryResult } = useForm<IResource>({
    action: 'edit',
    resource: 'epimetheus/resources',
    id: resourceId
  });

  const { mutate: updateResource } = useUpdate<IResource>();

  const change = (value: { value: string; label: string }) => {
    updateResource({
      resource: 'epimetheus/resources',
      id: resourceId!,
      values: {
        tag_ids: value
      }
    });
  };

  const { selectProps: tagsSelectProps } = useSelect<ITag>({
    resource: 'epimetheus/tags',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.tags.map(value => value.id),

    onSearch: value => [
      {
        field: 'q',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Form {...formProps} layout="horizontal">
      <Form.Item
        label="Tags"
        name="tag_ids"
        initialValue={queryResult?.data?.data.tags.map(value => value.id)}
      >
        <Select
          {...tagsSelectProps}
          disabled={disabled}
          mode="multiple"
          onChange={value => {
            change(value);
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default TagSelect;
