import {
  AcademicRecordEquivalencyCreate,
  AcademicRecordEquivalencyList,
  AcademicRecordEquivalencyShow
} from 'pages/academic/academic_record_equivalencies';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate/academic_records/equivalencies',
  label: 'Equivalências',
  list: AcademicRecordEquivalencyList,
  create: AcademicRecordEquivalencyCreate,
  show: AcademicRecordEquivalencyShow,
  actions: ['list', 'show', 'create'],
  parentName: 'academic'
};

export default generateResources(resource);
