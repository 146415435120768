import {
  IAssessmentAttempt,
  IAssessmentQuestion
} from 'interfaces/assessments';
import { getAPI } from 'requests/api';

const ASSESSMENT_ATTEMPT_MAP = new Map([
  ['unknown', 'Desconhecida'],
  ['started', 'Iniciada'],
  ['evaluation_started', 'Em correção'],
  ['pending_evaluation', 'Aguardando correção'],
  ['evaluated', 'Corrigida'],
  ['passed', 'Aprovada'],
  ['failed', 'Reprovada'],
  ['scored', 'Nota calculada']
]);

export const QUESTION_STATUSES_MAP: Record<
  IAssessmentAttempt['status'],
  { label: string; color: string }
> = {
  correct: {
    label: 'Correta',
    color: 'success'
  },
  blank: {
    label: 'Em branco',
    color: 'default'
  },
  wrong: {
    label: 'Errada',
    color: 'error'
  },
  nullified: {
    label: 'Anulada',
    color: 'success'
  }
};
export const QUESTION_STATE_MAP: Record<
  IAssessmentQuestion['status'],
  { label: string; color: string }
> = {
  active: {
    label: 'Ativa',
    color: 'blue'
  },
  inactive: {
    label: 'Inativa',
    color: 'gray'
  },
  nullified: {
    label: 'Anulada',
    color: 'red'
  }
};

export const translateAssessmentAttemptStatus = (status: string) => {
  return ASSESSMENT_ATTEMPT_MAP.get(status);
};

export const fetchAssessmentsAttemptsLogs = async (attemptId: string) => {
  const { data } = await getAPI().get(
    `/admin/assessments/attempts/${attemptId}/logs`
  );

  return data;
};
