import {
  ForumReportsList,
  ForumReportsShow
} from 'pages/academic/forum_reports';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'forums/resource_reports',
  label: 'Denúncias fórum',
  list: ForumReportsList,
  show: ForumReportsShow,
  actions: ['list', 'show'],
  parentName: 'academic'
};

export default generateResources(resource);
