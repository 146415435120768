import { generateResources } from 'utils/GenerateResouces';
import { teachingIcon } from '../assets/icons/teaching';

const resource = {
  name: 'teaching_resources',
  label: 'Recursos de Ensino',
  icon: teachingIcon
};

export default generateResources(resource);
