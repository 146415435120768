import dayjs from 'dayjs';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography
} from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { useCallback, useState } from 'react';

export interface ClassroomInfoProps {
  classroom: IClassroom;
  formProps: FormProps;
}

const ClassroomInfo = ({ classroom, formProps }: ClassroomInfoProps) => {
  const isClassroomClosed = dayjs(classroom.ends_at).isBefore(dayjs());
  const [attendance, setAttendance] = useState(
    Boolean(classroom.minimum_attendance)
  );

  const handleAttendance = useCallback(() => {
    formProps.form?.setFieldValue('minimum_attendance', null);
    setAttendance(!attendance);
  }, [attendance, formProps]);

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        Informações gerais da turma
      </Divider>
      <Form.Item label="Disciplina" name={['discipline', 'name']}>
        <Input className="w-full" disabled />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col md={6}>
          <Form.Item label="ID da turma" name="number">
            <Input className="w-full" disabled />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Código da turma" name="code">
            <Input className="w-full" disabled />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="N° de vagas"
            name="seats_total"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Nota mínima (0-100)"
            name="passing_score"
            rules={[
              {
                required: false
              }
            ]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Space className="mb-4">
        <Switch
          disabled={isClassroomClosed}
          defaultValue={attendance}
          onChange={handleAttendance}
        />
        <Typography.Paragraph className="!mb-0">
          Frequência obrigatória
        </Typography.Paragraph>
        <Tooltip
          placement="topLeft"
          title={'Não é possivel alterar frequência de turma fora de vigência!'}
          arrow={true}
        >
          <div className="flex item-center justify-center px-1 border border-solid rounded-full w-[15px] h-[15px]">
            <span className="mb-3 opacity-75">?</span>
          </div>
        </Tooltip>
      </Space>
      {attendance && (
        <Col span={6}>
          <Form.Item
            label="Frequência mínima"
            name="minimum_attendance"
            rules={[
              {
                required: false
              }
            ]}
          >
            <InputNumber
              disabled={formProps.form?.getFieldValue('minimum_attendance')}
              min={0}
              max={100}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      )}
      <Divider
        orientation="left"
        orientationMargin="0"
        className="required-mark"
      >
        Vigência (Horário de Brasília)
      </Divider>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <Form.Item
            name="dateRangeValidity"
            rules={[
              {
                required: true,
                message: 'Data de vigência é obrigatória.'
              }
            ]}
          >
            <DatePicker.RangePicker
              className="w-full"
              showTime
              placeholder={[
                'início da turma (horário de Brasília)',
                'término da turma (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        Acesso ao tira dúvidas (Horário de Brasília)
      </Divider>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <Form.Item name="dateRangeDiscussion">
            <DatePicker.RangePicker
              showTime
              className="w-full"
              placeholder={[
                'Data de início (horário de Brasília)',
                'Data de término (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider
        orientation="left"
        orientationMargin="0"
        className="required-mark"
      >
        Acesso a visualização do conteúdo (Horário de Brasília)
      </Divider>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <Form.Item
            name="dateRangeContent"
            rules={[
              {
                required: true,
                message: 'Data de acesso ao conteúdo é obrigatória.'
              }
            ]}
          >
            <DatePicker.RangePicker
              showTime
              className="w-full"
              placeholder={[
                'Data de início (horário de Brasília)',
                'Data de término (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        Outras informações
      </Divider>
      <Form.Item label="Descrição" name="description">
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        key="auto_enroll"
        name="auto_enroll"
        label="Habilitar inscrição automática na turma?"
      >
        <Switch defaultChecked={classroom?.auto_enroll} />
      </Form.Item>
    </>
  );
};

export default ClassroomInfo;
