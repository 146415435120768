import FilterForm from 'components/forms/FilterForm';
import useSelectCourseByType from 'hooks/useSelectCourseByType';
import {
  Col,
  Form,
  FormProps,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space
} from 'antd';
import { useCallback } from 'react';

export interface ICompanyContractsFilter {
  by_course_type: string;
  by_code: string;
  by_course_id: string;
  starts_after: Date;
  expires_before: Date;
}

interface ContractsFilterProps {
  searchFormProps: FormProps<ICompanyContractsFilter>;
}

export const ContractsFilter = ({ searchFormProps }: ContractsFilterProps) => {
  const { selectProps, selectedType, setSelectedType } =
    useSelectCourseByType();

  const onChangeType = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;
      setSelectedType(value);
    },
    [setSelectedType]
  );

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <FilterForm
        searchFormProps={searchFormProps}
        okButtonProps={{
          type: 'default'
        }}
      >
        <Col span={24} style={{ marginBottom: '16px' }}>
          <Form.Item
            label="Tipo de curso"
            name="by_grandparent_type"
            className="!mb-0"
            initialValue="Course"
          >
            <Radio.Group
              onChange={onChangeType}
              value={selectedType}
              defaultValue="Course"
            >
              <Radio value="Course">Curso livre</Radio>
              <Radio value="Graduate::Discipline">Pós-graduação</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Row gutter={[16, 16]} className="w-full">
          <Col xs={24} md={10}>
            <Form.Item label="Cursos" name="course_id">
              <Select placeholder="Digite..." {...selectProps} allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} md={6}>
            <Form.Item label="Contrato" name="contract">
              <Input placeholder="Contrato" />
            </Form.Item>
          </Col>
        </Row>
      </FilterForm>
    </Space>
  );
};
