import AssetUpload from 'components/AssetUpload';
import CourseProfessor from 'components/professors/CourseProfessor';
import TabDocumentation from './components/TabDocumentation';
import TabStudents from './components/TabStudents';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, InputNumber, Select, Tabs } from 'antd';
import { IGraduateCourseShow } from 'interfaces/graduate_courses';
import { TabDisciplines } from './components/TabDisciplines';
import { useState } from 'react';

export const GraduateCourseEdit = () => {
  const [activeTab, setActiveTab] = useState('info');

  const { formProps, saveButtonProps, queryResult } =
    useForm<IGraduateCourseShow>({
      redirect: 'edit'
    });

  return (
    <Edit
      title="Editar curso regulado"
      footerButtonProps={{
        style: {
          justifyContent: 'flex-end',
          display: ['info', 'professor'].includes(activeTab) ? 'flex' : 'none'
        }
      }}
      saveButtonProps={saveButtonProps}
    >
      <Tabs
        defaultActiveKey={activeTab}
        onChange={activeKey => setActiveTab(activeKey)}
      >
        <Tabs.TabPane tab="Informações gerais" key="info">
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tipo"
              name="kind"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select disabled>
                <Select.Option value="graduate">Pós Graduação</Select.Option>
                <Select.Option value="undergraduate">Graduação</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Código e-MEC" name="emec" className="max-w-xs">
              <Input />
            </Form.Item>
            <Form.Item
              label="Duração (horas)"
              name="duration_in_hours"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Nota mínima (0 - 100)"
              name="min_evaluation_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
            <Form.Item
              label="Card"
              name={['media', 'card_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="course"
                src={queryResult?.data?.data?.media?.card_image}
              />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Módulos e Disciplinas" key="courseContent">
          <TabDisciplines />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Alunos" key="courseStudents">
          <TabStudents />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Documentação" key="courseDocumentation">
          <TabDocumentation />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Coordenador do curso" key="professor">
          {queryResult?.data?.data && (
            <CourseProfessor course={queryResult.data.data} />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
