import * as Icons from '@ant-design/icons';
import ConfirmationModal from '../modal/ConfirmationModal';
import FiscalsTable from 'components/orders/FiscalsTable';
import ItemsTable from 'components/orders/ItemsTable';
import PartialRefundsTable from 'components/orders/partialRefund/PartialRefundTable';
import PaymentsTable from 'components/orders/PaymentsTable';
import RefundsTable from 'components/orders/refund/RefundTable';
import { Alert, Col, Divider, Row, Tooltip, Typography } from 'antd';
import { Button, Stack } from '@qcx/ui';
import { CopyToClipboard } from '../CopyToClipboard';
import { IOrder } from 'interfaces/orders';
import { formatMoney } from 'services/money';
import { translateStatus } from 'services/orders';
import { useApiUrl, useCan, useCustomMutation } from '@refinedev/core';
import { useModal } from '@refinedev/antd';
import { useState } from 'react';

const { Title, Text } = Typography;

interface Props {
  order: IOrder;
}

const head = (order: IOrder) => [
  { title: 'ID do pedido', text: order.id, allowCopy: true },
  { title: 'Número do pedido', text: order.order_number, allowCopy: true },
  { title: 'Status', text: translateStatus(order.status) },
  { title: 'Aluno', text: order.user.name },
  { title: 'E-mail', text: order.user.email, allowCopy: true },
  { title: 'Valor total do pedido', text: formatMoney(order.amount) },
  { title: 'CEP', text: order.data.zip_code, allowCopy: true }
];

const Head = ({ order }: Props) => (
  <Row>
    {head(order).map(item => (
      <Col key={item.title} md={6} style={{ margin: 10 }}>
        <Title level={5}>{item.title}</Title>

        <div className="flex gap-2">
          <Text>{item.text}</Text>
          {item.allowCopy && <CopyToClipboard text={String(item.text)} />}
        </div>
      </Col>
    ))}
  </Row>
);

const Items = ({ order }: Props) => (
  <>
    <Title level={4}>Items do pedido</Title>
    <ItemsTable items={order.items} />
  </>
);

const Payments = ({ order }: Props) => (
  <>
    <Title
      level={4}
      style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
    >
      Pagamentos
      <Tooltip title="Listagem de tentativas de pagamento de um pedido">
        <Icons.QuestionCircleOutlined />
      </Tooltip>
    </Title>
    <Alert
      message="O campo 'detalhes' indica a razão da rejeição de um pagamento no cartão de crédito"
      type="info"
      showIcon
    />
    <PaymentsTable order={order} />
  </>
);

const Fiscals = ({ order }: Props) => {
  const { data: permission } = useCan({
    resource: 'payment_plans',
    action: 'interrupt'
  });

  const fiscals = order?.hermes_order?.fiscals;

  const fiscalsIds = fiscals?.map(fiscal => {
    if (fiscal.status === 'issue') {
      return fiscal.id;
    }
  });

  const fiscalsInterrupted = fiscals?.some(
    fiscal => fiscal.status === 'interrupted'
  );

  const receipts = fiscals?.flatMap(fiscal => fiscal.receipts || []);
  const { show: showModal, modalProps, close: closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  const modalCopy = {
    okText: 'Sim, quero interromper',
    bodyTitle:
      'Tem certeza que quer interromper a emissão das próximas notas fiscais?',
    bodyText:
      'Ao confirmar, você estará interrompendo as futuras emissões de notas fiscais desde pedido'
  };

  const showInterruptBtn =
    permission?.can && fiscalsIds?.length > 0 && fiscalsIds[0] !== undefined;

  const handleFiscalCancelation = () => {
    setLoading(true);
    mutate(
      {
        url: `${apiUrl}/orders/${order.id}/fiscals/interrupt`,
        method: 'post',
        values: {
          fiscal_ids: fiscalsIds
        },
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message: 'As emissões foram interrompidas',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );

    setLoading(false);
  };

  return (
    fiscals && (
      <>
        <Stack css={{ gap: '$6' }}>
          <Title level={4}>Notas Fiscais</Title>
          {fiscalsInterrupted && (
            <Alert
              message="A emissão de notas fiscais deste pedido foi interrompida"
              type="warning"
              showIcon
            />
          )}
          {showInterruptBtn && (
            <Button onClick={() => showModal()}>
              Interromper emissão de notas fiscais
            </Button>
          )}
        </Stack>
        <ConfirmationModal
          closable={false}
          confirmLoading={loading}
          onOk={handleFiscalCancelation}
          okType="danger"
          okText={modalCopy.okText}
          bodyTitle={modalCopy.bodyTitle}
          bodyText={modalCopy.bodyText}
          width={400}
          {...modalProps}
        />

        <FiscalsTable receipts={receipts} />
      </>
    )
  );
};

const PartialRefunds = ({ order }: Props) => (
  <>
    <Title
      level={4}
      style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
    >
      Reembolso Parcial
      <Tooltip title="Corresponde a um valor avulso ou item do pedido">
        <Icons.QuestionCircleOutlined />
      </Tooltip>
    </Title>
    <Alert
      message="Valor avulso deve estar disponível sobre o total do pedido, considerando os reembolsos que ainda estão sendo processados"
      type="info"
      showIcon
    />
    <PartialRefundsTable
      order={order}
      onUpdate={() => {
        return;
      }}
    />
  </>
);

const Refunds = ({ order }: Props) => (
  <>
    <Title
      level={4}
      style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
    >
      Reembolso Total
      <Tooltip title="Corresponde ao valor cheio do pedido">
        <Icons.QuestionCircleOutlined />
      </Tooltip>
    </Title>
    <Alert
      message="Só é possível realizar um reembolso total por vez. Em caso de erro, após o processamento, será possível tentar novamente."
      type="warning"
      showIcon
    />
    <RefundsTable
      order={order}
      onUpdate={() => {
        return;
      }}
    />
  </>
);

const Details = ({ order }: Props) => {
  return (
    <div className="flex gap-4 flex-col">
      <Head order={order} />
      <Divider />
      <Fiscals order={order} />
      <Divider />
      <Items order={order} />
      <Divider />
      <Payments order={order} />
      <Divider />
      <Refunds order={order} />
      <Divider />
      <PartialRefunds order={order} />
    </div>
  );
};

export default Details;
