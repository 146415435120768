import {
  GraduateDisciplineCreate,
  GraduateDisciplineEdit,
  GraduateDisciplineList,
  GraduateDisciplineShow
} from 'pages/graduate_courses/graduate_disciplines';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate_disciplines',
  label: 'Disciplinas',
  list: GraduateDisciplineList,
  show: GraduateDisciplineShow,
  edit: GraduateDisciplineEdit,
  create: GraduateDisciplineCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: '_courses'
};

export default generateResources(resource);
