import { LogDeletedUserList } from 'pages/admin/log_deleted_users';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'log_deleted_users',
  label: 'Usuários excluídos',
  list: LogDeletedUserList,
  actions: ['list'],
  parentName: 'audit'
};

export default generateResources(resource);
