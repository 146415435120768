import {
  CreateManyGeneralRules,
  PaymentPlanDealsDiscountsList
} from 'pages/ecommerce/discount_campaings/payment_plan_deals';
import { CreateProductPlanDealRule } from 'pages/ecommerce/discount_campaings/payment_plan_deals/CreateManyProductRule';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'discount_campaigns',
  label: 'Descontos para inadimplência',
  list: PaymentPlanDealsDiscountsList,
  create: CreateManyGeneralRules,
  show: CreateProductPlanDealRule,
  actions: ['create', 'list', 'show'],
  parentName: '_deal'
};

export default generateResources(resource);
