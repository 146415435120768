import AssetUpload from 'components/AssetUpload';
import SlugInput from '../../../components/form-items/SlugInput';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IInstructor } from 'interfaces/instructors';

export const InstructorCreate = () => {
  const { formProps, saveButtonProps } = useForm<IInstructor>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar instrutor">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Avatar"
          name="avatar"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload accept="image/png, image/jpeg" type="instructor" />
        </Form.Item>
        <SlugInput />
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Cabeçalho"
          name="headline"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Biografia"
          name="bio"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  );
};
