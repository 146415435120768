import dayjs from 'dayjs';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography
} from 'antd';
import { IEcommerceProfitCenter } from 'interfaces/ecommerce_profit_centers';
import { IFiscalTemplate } from 'interfaces/fiscals';
import { IGatewayRule } from 'interfaces/gateways';
import { IOffer } from 'interfaces/offers';
import { IProduct } from 'interfaces/products';
import {
  KINDS,
  STATUSES,
  translateKind,
  translateStatus
} from 'services/offers';
import { getTenant } from 'services/tenants';
import { useCreate, useModalReturnType } from '@refinedev/core';
import { useSelect } from '@refinedev/antd';
import { useState } from 'react';

interface CreateOfferModalProps {
  modal: useModalReturnType;
  product: IProduct | undefined;
  onUpdate: () => void;
}

const CreateOfferModal = ({
  modal,
  product,
  onUpdate
}: CreateOfferModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutateAsync: offerMutateAsync } = useCreate<IOffer>();

  form.setFields([{ name: 'name', value: product?.name }]);

  const { selectProps: fiscalTemplateSelectProps } = useSelect<IFiscalTemplate>(
    {
      resource: 'fiscal_templates',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const { selectProps: ecommerceProfitCentersSelectProps } =
    useSelect<IEcommerceProfitCenter>({
      resource: 'ecommerce/profit_centers',
      optionLabel: 'code',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[code]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  const { selectProps: gatewayRuleSelectProps } = useSelect<IGatewayRule>({
    resource: 'offers/gateways/rules',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: feesRuleSelectProps } = useSelect<IGatewayRule>({
    resource: 'offers/fees',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ]
  });

  const tenant = getTenant();
  const serviceDatesEnabled =
    !tenant.disabledFeatures?.includes('serviceDates');

  const onSubmit = async ({
    name,
    fiscal_template_id,
    status,
    kind,
    reference_price,
    price,
    time_in_days,
    profit_center_id,
    gateway_rule_id,
    service_start_at,
    service_duration_in_months,
    checkout_scheme_config: { boleto, pix, credit_card, financing_credit_card },
    fee_id
  }: {
    name: string;
    fiscal_template_id: string;
    status: string;
    kind: string;
    reference_price: number;
    price: number;
    time_in_days: number;
    profit_center_id: string;
    gateway_rule_id: string;
    service_start_at: string;
    service_duration_in_months: string;
    checkout_scheme_config: {
      boleto: { max_times: number; financing: boolean };
      pix: { max_times: number; financing: boolean };
      credit_card: { max_times: number; financing: boolean };
      financing_credit_card: { max_times: number; financing: boolean };
    };
    fee_id: string;
  }) => {
    setLoading(true);

    offerMutateAsync({
      resource: 'offers',
      values: {
        name: name,
        fiscal_template_id: fiscal_template_id,
        profit_center_id: profit_center_id,
        gateway_rule_id: gateway_rule_id,
        status: status,
        kind: kind,
        service_start_at: service_start_at,
        service_duration_in_months: service_duration_in_months,
        fee_id: fee_id,
        checkout_scheme_config: {
          boleto: {
            max_times: boleto.max_times,
            financing: boleto.financing
          },
          pix: {
            max_times: pix.max_times,
            financing: pix.financing
          },
          credit_card: {
            max_times: credit_card.max_times,
            financing: credit_card.financing
          },
          financing_credit_card: {
            max_times: financing_credit_card.max_times,
            financing: financing_credit_card.financing
          }
        },
        items: [
          {
            name: name,
            reference_price: reference_price,
            price: price,
            product_id: product?.id,
            time_in_days: time_in_days
          }
        ]
      }
    })
      .then(() => {
        onUpdate();
        modal.close();
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClick = async () => {
    const {
      name,
      fiscal_template_id,
      profit_center_id,
      gateway_rule_id,
      status,
      kind,
      reference_price,
      price,
      time_in_days,
      service_start_at,
      service_duration_in_months,
      fee_id,
      checkout_scheme_config: {
        boleto,
        pix,
        credit_card,
        financing_credit_card
      }
    } = await form.validateFields();

    onSubmit({
      name,
      fiscal_template_id,
      profit_center_id,
      gateway_rule_id,
      status,
      kind,
      reference_price,
      price,
      time_in_days,
      service_start_at,
      service_duration_in_months,
      fee_id,
      checkout_scheme_config: {
        boleto: {
          max_times: boleto.max_times,
          financing: boleto.financing
        },
        pix: {
          max_times: pix.max_times,
          financing: pix.financing
        },
        credit_card: {
          max_times: credit_card.max_times,
          financing: credit_card.financing
        },
        financing_credit_card: {
          max_times: financing_credit_card.max_times,
          financing: financing_credit_card.financing
        }
      }
    });
  };

  const [boletoFinancing, setBoletoFinancing] = useState(false);
  const [pixFinancing, setPixFinancing] = useState(false);
  const [creditCardFinancing, setCreditCardFinancing] = useState(false);

  return (
    <Modal
      width={800}
      title="Criar Oferta"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={modal.close}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onClick}>
          Criar
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{
          checkout_scheme_config: {
            pix: { max_times: 1, financing: false },
            boleto: { max_times: 1, financing: false },
            credit_card: { max_times: 12, financing: false },
            financing_credit_card: { max_times: 1, financing: false }
          }
        }}
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Período de acesso ao curso"
              name="time_in_days"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                className="w-full"
                precision={0}
                max={730}
                min={1}
                addonAfter="dias"
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select value="active">
                {STATUSES.map(status => (
                  <Select.Option key={status}>
                    {translateStatus(status)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Form.Item
              label="Preço referência"
              name="reference_price"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber className="w-full" addonBefore="R$" precision={2} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Preço"
              name="price"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber className="w-full" addonBefore="R$" precision={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="Tipo da Oferta" name="kind">
              <Select value="online">
                {KINDS.map(kind => (
                  <Select.Option key={kind}>
                    {translateKind(kind)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={3}>Pagamento</Typography.Title>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Regra de Gateway"
              name="gateway_rule_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select {...gatewayRuleSelectProps} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Regra de Juros e Multa" name="fee_id">
              <Select {...feesRuleSelectProps} />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4}>Boleto</Typography.Title>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Parcelas"
              name={['checkout_scheme_config', 'boleto', 'max_times']}
            >
              <Select>
                <Select.Option key="disabled" value={0}>
                  Desabilitar Boleto
                </Select.Option>
                {[...Array.from({ length: boletoFinancing ? 24 : 1 })].map(
                  (x, index) => (
                    <Select.Option key={index} value={index + 1}>
                      {index + 1}x
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Financiamento"
              name={['checkout_scheme_config', 'boleto', 'financing']}
            >
              <Select
                onChange={(value: unknown) => {
                  setBoletoFinancing(value as boolean);
                }}
              >
                <Select.Option value={true}>SIM</Select.Option>
                <Select.Option value={false}>NÃO</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4}>Pix</Typography.Title>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Parcelas"
              name={['checkout_scheme_config', 'pix', 'max_times']}
            >
              <Select>
                <Select.Option key="disabled" value={0}>
                  Desabilitar Pix
                </Select.Option>
                {[...Array.from({ length: pixFinancing ? 24 : 1 })].map(
                  (x, index) => (
                    <Select.Option key={index} value={index + 1}>
                      {index + 1}x
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Financiamento"
              name={['checkout_scheme_config', 'pix', 'financing']}
            >
              <Select
                onChange={value => {
                  setPixFinancing(value as boolean);
                }}
              >
                <Select.Option value={true}>SIM</Select.Option>
                <Select.Option value={false}>NÃO</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4}>Cartão com limite</Typography.Title>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Parcelas"
              name={['checkout_scheme_config', 'credit_card', 'max_times']}
            >
              <Select>
                <Select.Option key="disabled" value={0}>
                  Desabilitar Cartão
                </Select.Option>
                {[
                  ...Array.from({
                    length: 12
                  })
                ].map((x, index) => (
                  <Select.Option key={index} value={index + 1}>
                    {index + 1}x
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Financiamento"
              name={['checkout_scheme_config', 'credit_card', 'financing']}
            >
              <Select disabled>
                <Select.Option value={false}>NÃO</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4}>Cartão sem limite</Typography.Title>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              label="Parcelas"
              name={[
                'checkout_scheme_config',
                'financing_credit_card',
                'max_times'
              ]}
            >
              <Select>
                {[
                  ...Array.from({
                    length: creditCardFinancing ? 24 : 1
                  })
                ].map((x, index) => (
                  <Select.Option key={index} value={index + 1}>
                    {index + 1}x
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Habilitado"
              name={[
                'checkout_scheme_config',
                'financing_credit_card',
                'financing'
              ]}
            >
              <Select
                onChange={value => {
                  setCreditCardFinancing(value as boolean);
                }}
              >
                <Select.Option value={false}>NÃO</Select.Option>
                <Select.Option value={true}>SIM</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={3}>Fiscal</Typography.Title>
        <Row gutter={16}>
          <Col md={18}>
            <Form.Item
              label="Template Fiscal"
              name="fiscal_template_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select {...fiscalTemplateSelectProps} />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Centro de Resultado" name="profit_center_id">
              <Select {...ecommerceProfitCentersSelectProps} allowClear />
            </Form.Item>
          </Col>
        </Row>
        {serviceDatesEnabled && (
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form.Item
                label="Início da prestação de serviço"
                name="service_start_at"
                getValueProps={value => {
                  return {
                    value: value ? dayjs(value) : ''
                  };
                }}
                rules={[
                  {
                    required: false
                  }
                ]}
              >
                <DatePicker className="w-full" format="DD/MM/YYYY" allowClear />
              </Form.Item>
            </Col>

            <Col md={12}>
              <Form.Item
                label="Tempo da prestação de serviço"
                name="service_duration_in_months"
              >
                <InputNumber
                  className="w-full"
                  precision={0}
                  max={24}
                  min={1}
                  addonAfter="meses"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default CreateOfferModal;
