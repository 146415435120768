import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, FormProps, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  TagField,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { IProductBonus } from '../../../interfaces/product_bonuses';
import { QUESTION_STATE_MAP } from 'services/assessments';

export const AssessmentQuestionList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAssessmentQuestion,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { assessment_id, statement, status } = params;

      filters.push(
        {
          field: 'filter[assessment]',
          operator: 'eq',
          value: assessment_id
        },
        {
          field: 'filter[statement]',
          operator: 'eq',
          value: statement
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'pending_evaluation'
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    }
  });

  interface IFilter {
    statement: string;
    assessment_id: string;
    status: string;
  }

  interface FilterFormProps {
    searchFormProps: FormProps<IFilter>;
  }

  const Filter = ({ searchFormProps }: FilterFormProps) => {
    const { selectProps: assessmentSelectProps } = useSelect<IAssessment>({
      resource: 'assessments',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[full_name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

    return (
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Questão" name="statement">
          <Input placeholder="Digite o enunciado da questão" />
        </Form.Item>
        <Form.Item label="Curso | Simulado" name="assessment_id">
          <Select
            placeholder="Digite..."
            style={{ minWidth: 200 }}
            {...assessmentSelectProps}
          />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select
            placeholder="Selecione..."
            style={{ minWidth: 200 }}
            defaultValue={'active'}
          >
            <Select.Option value={'active'}>Ativo</Select.Option>
            <Select.Option value={'inactive'} key={'inactive'}>
              Inativo
            </Select.Option>
            <Select.Option value={'nullified'} key={'nullified'}>
              Anulado
            </Select.Option>
            <Select.Option value={null} key={'all'}>
              Todos
            </Select.Option>
          </Select>
        </Form.Item>
      </FilterForm>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Filter searchFormProps={searchFormProps} />
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="plain_statement"
              key="plain_statement"
              title="Questão"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="assessment_name"
              key="assessment_name"
              title="Curso | Simulado"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="alternatives"
              key="alternatives"
              title="Alternativas"
              align="center"
              render={value => <TextField value={value.length} />}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={QUESTION_STATE_MAP[value].color}
                  value={QUESTION_STATE_MAP[value].label}
                />
              )}
            />

            <Table.Column<IProductBonus>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
