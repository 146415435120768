import useSelectCourseByType from 'hooks/useSelectCourseByType';
import {
  DatePicker,
  Form,
  FormInstance,
  FormProps,
  Input,
  InputNumber,
  RadioChangeEvent,
  Select
} from 'antd';
import { ICompany } from 'interfaces/companies';
import { IContract } from 'interfaces/contracts';
import { Radio } from 'antd/lib';
import { toSaoPauloTimeZone } from 'services/date';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface ContractFormProps {
  form: FormInstance<IContract>;
  formProps: FormProps<IContract>;
  company: ICompany;
}

const convertToTimeZoneRange = (startDate?: string, endDate?: string) => {
  return [
    startDate ? toSaoPauloTimeZone(startDate) : null,
    endDate ? toSaoPauloTimeZone(endDate) : null
  ];
};

export const ContractForm = ({
  form,
  formProps,
  company
}: ContractFormProps) => {
  const { id } = useParams();

  const { selectProps, selectedType, setSelectedType } =
    useSelectCourseByType();

  const onChangeType = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;
      setSelectedType(value);
    },
    [setSelectedType]
  );

  const initialValues = useMemo(() => {
    const initialValues = { ...formProps.initialValues };

    initialValues.course_type = 'Course';

    initialValues.rangeDate = convertToTimeZoneRange(
      initialValues?.starts_at,
      initialValues?.expires_at
    );

    return initialValues;
  }, [formProps.initialValues]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (range: any) => {
    const [start, end] = range;
    form.setFieldValue('starts_at', start);
    form.setFieldValue('expires_at', end);
  };

  return (
    <Form {...formProps} layout="vertical" initialValues={initialValues}>
      <Form.Item
        label="Nº do contrato"
        name="code"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input disabled={Boolean(id)} style={{ width: '240px' }} />
      </Form.Item>

      <Form.Item
        label="Empresa"
        name="company.name"
        initialValue={company.name}
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item label="Tipo de curso" name="course_type" initialValue="Course">
        <Radio.Group
          onChange={onChangeType}
          value={selectedType}
          defaultValue="Course"
          disabled={Boolean(id)}
        >
          <Radio value="Course">Curso livre</Radio>
          <Radio value="Graduate::Course">Pós-graduação</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Curso"
        name="course_id"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select
          disabled={Boolean(id)}
          placeholder="Digite..."
          {...selectProps}
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Quantidade máxima de alunos"
        name="max_enrollments"
        rules={[
          {
            required: true
          }
        ]}
      >
        <InputNumber style={{ width: '240px' }} />
      </Form.Item>

      <Form.Item
        label="Vigência"
        name="rangeDate"
        rules={[
          {
            required: true
          }
        ]}
      >
        <DatePicker.RangePicker
          className="w-full"
          onChange={handleDateChange}
          placeholder={[
            'Início (horário de Brasília)',
            'Término (horário de Brasília)'
          ]}
          format="DD/MM/YYYY"
        />
      </Form.Item>

      <Form.Item name="starts_at" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="expires_at" hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
