import FilterForm from '../../../components/forms/FilterForm';
import {
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Table
} from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { IAssessment } from 'interfaces/assessments';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { IProductBonus } from '../../../interfaces/product_bonuses';
import { useCallback, useMemo, useState } from 'react';

interface IFilter {
  name: string;
  by_parent_id: string;
  by_parent_type: string;
}

export const AssessmentList = () => {
  const [selectedType, setSelectedType] = useState('Course');

  const { tableProps, searchFormProps } = useTable<
    IAssessment,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_parent_id, by_parent_type, name } = params;

      filters.push(
        {
          field: 'filter[by_parent_id]',
          operator: 'eq',
          value: by_parent_id
        },
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: by_parent_type
        },
        {
          field: 'filter[full_name]',
          operator: 'eq',
          value: name
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: 'Course'
        }
      ]
    }
  });

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const onChangeType = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;
    setSelectedType(value);
  }, []);

  const selectProps = useMemo(() => {
    return selectedType === 'Course'
      ? courseSelectProps
      : disciplineSelectProps;
  }, [courseSelectProps, disciplineSelectProps, selectedType]);

  return (
    <List>
      <FilterForm searchFormProps={searchFormProps}>
        <Row gutter={[24, 12]} className="w-full">
          <Col span={24}>
            <Form.Item
              label="Tipo de curso"
              name="by_parent_type"
              className="!mb-0"
            >
              <Radio.Group
                onChange={onChangeType}
                value={selectedType}
                defaultValue="Course"
              >
                <Radio value="Course">Curso</Radio>
                <Radio value="Graduate::Discipline">Disciplina</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 12]} className="w-full">
          <Col xs={24} md={12}>
            <Form.Item
              label={selectedType === 'Course' ? 'Curso' : 'Disciplina'}
              name="by_parent_id"
              className="w-full"
            >
              <Select placeholder="Digite..." allowClear {...selectProps} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Nome da avaliação" name="name">
              <Input placeholder="Simulado 1" />
            </Form.Item>
          </Col>
        </Row>
      </FilterForm>

      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome da avaliação"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="parent_type"
          key="parent_type"
          title="Tipo"
          render={value => (
            <TextField value={value === 'Course' ? 'Curso' : 'Disciplina'} />
          )}
        />
        <Table.Column
          dataIndex="course_name"
          key="course_name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="max_attempts"
          key="max_attempts"
          title="Tentativas"
          align="center"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="passing_score"
          key="passing_score"
          title="Nota mínima"
          align="center"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IProductBonus>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
