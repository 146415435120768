import { LiveCreate, LiveList } from 'pages/prometheus/lives';
import { LiveShow } from 'pages/prometheus/lives/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'prometheus/lives',
  label: 'Lives',
  create: LiveCreate,
  list: LiveList,
  show: LiveShow,
  actions: ['list', 'show', 'create'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
