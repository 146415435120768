import { DiscountCampaign } from './GeneralRule';
import { DiscountCreatePayload } from 'interfaces/discount';
import {
  GeneralPaymentPlanDealsDiscountsForm,
  ManyGeneralPaymentPlanDealsDiscountsForm
} from './create';
import { ResumeDiscountForm } from './components/ResumeDiscountForm';
import {
  ResumeManyDiscountForm,
  RuleTypeI
} from './components/ResumeManyDiscountForm';
import { SetDiscountRuleType } from './components/SetDiscountRuleType';
import { Stack } from '@qcx/ui';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTable } from '@refinedev/core';

export const CreateGeneralRule = () => {
  const [createData, setCreateData] = useState<DiscountCreatePayload>({});
  const [resourceType, setResType] = useState('product_id');

  const updateCreateData = ({
    products,
    discount_type,
    discount_value,
    invoice_due
  }: {
    products: { id: string; name: string }[];
    discount_type: string;
    discount_value: number;
    invoice_due?: number;
  }) => {
    setCreateData({
      ...createData,
      products,
      discount_type,
      discount_value,
      invoice_due
    });
  };

  const setResourceType = (value: string) => {
    setResType(value);
  };

  const navigate = useNavigate();

  const onCreate = () => {
    navigate('/sales/discount_campaigns');
  };

  return (
    <div className="bg-white items-start p-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <h3 className="flex items-center gap-2">
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
            Inadimplência /
          </span>
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.85)]">
            Regras de desconto
          </span>
        </h3>
        <h1 className="font-bold text-xl leading-7 text-[rgba(0,0,0,0.85)]">
          Criar Regra de desconto
        </h1>
      </div>
      <Stack
        css={{
          d: 'flex',
          justifyContent: 'space-between',
          gap: '$10',
          mt: '$4'
        }}
      >
        <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
          <Stack css={{ w: '$full', flexDir: 'column' }}>
            <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
              A Regra Geral é aplicada à todos os produtos, a menos que ele seja
              excluído individualmente de cada tipo de desconto.
            </p>
            <SetDiscountRuleType
              onChange={setResourceType}
              defaultValue={resourceType}
            />
          </Stack>
          <GeneralPaymentPlanDealsDiscountsForm
            type={resourceType}
            onChange={updateCreateData}
          />
        </Stack>
        <ResumeDiscountForm
          showCancel
          operator="!="
          {...createData}
          onCreate={onCreate}
        />
      </Stack>
    </div>
  );
};

export const CreateManyGeneralRules = () => {
  const [productCreateData, setproductCreateData] =
    useState<DiscountCreatePayload>({});
  const [invoiceDueCreateData, setInvoiceDueCreateData] =
    useState<DiscountCreatePayload>({});
  const [resourceType, setResType] = useState('product_id');

  const updateCreateData = ({
    productRule,
    invoiceRule
  }: {
    productRule: RuleTypeI;
    invoiceRule: RuleTypeI;
  }) => {
    setInvoiceDueCreateData({
      ...invoiceRule
    });

    setproductCreateData({
      ...productRule
    });
  };

  const setResourceType = (value: string) => {
    setResType(value);
  };

  const navigate = useNavigate();

  const onCreate = () => {
    navigate('/_deal/discount_campaigns');
  };

  const { tableQueryResult: discountResults, setFilters: setDiscountFIlters } =
    useTable<DiscountCampaign>({
      resource: 'discount_campaigns',

      pagination: {
        pageSize: 100
      },

      permanentFilter: [
        {
          field: 'filter[rules][resource_type]',
          operator: 'eq',
          value: 'product_id'
        },
        {
          field: 'filter[rules][operator]',
          operator: 'eq',
          value: '!='
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    });

  const originalDiscounts = discountResults?.data?.data ?? [];

  return (
    <div className="bg-white items-start p-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <h3 className="flex items-center gap-2">
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
            Inadimplência /
          </span>
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.85)]">
            Regras de desconto
          </span>
        </h3>
        <h1 className="font-bold text-xl leading-7 text-[rgba(0,0,0,0.85)]">
          Criar Regra de desconto
        </h1>
      </div>
      <Stack
        css={{
          d: 'flex',
          justifyContent: 'space-between',
          gap: '$10',
          mt: '$4'
        }}
      >
        <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
          <Stack css={{ w: '$full', flexDir: 'column' }}>
            <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
              A Regra Geral é aplicada à todos os produtos, a menos que ele seja
              excluído individualmente de cada tipo de desconto.
            </p>
            <SetDiscountRuleType
              onChange={setResourceType}
              defaultValue={resourceType}
            />
          </Stack>
          <ManyGeneralPaymentPlanDealsDiscountsForm
            type={resourceType}
            onChange={updateCreateData}
          />
        </Stack>
        <ResumeManyDiscountForm
          showCancel
          operator="!="
          productRule={productCreateData}
          timeRule={invoiceDueCreateData}
          rulesToCancel={originalDiscounts.map(d => d.id)}
          onCreate={onCreate}
        />
      </Stack>
    </div>
  );
};
