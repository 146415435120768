import {
  GraduateCourseCreate,
  GraduateCourseEdit,
  GraduateCourseList,
  GraduateCourseShow
} from 'pages/graduate_courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate_courses',
  label: 'Cursos Regulados',
  list: GraduateCourseList,
  show: GraduateCourseShow,
  edit: GraduateCourseEdit,
  create: GraduateCourseCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: '_courses'
};

export default generateResources(resource);
