import {
  AssessmentAttemptEdit,
  AssessmentAttemptShow,
  AssessmentAttemptsList
} from 'pages/evaluations/assessment_attempts';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assessments/attempts',
  label: 'Histórico de Avaliações',
  list: AssessmentAttemptsList,
  edit: AssessmentAttemptEdit,
  show: AssessmentAttemptShow,
  actions: ['list', 'edit', 'show'],
  parentName: 'academic'
};

export default generateResources(resource);
