import React, { useCallback, useMemo, useState } from 'react';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography
} from 'antd';
import { IGraduateDiscipline } from '../../../interfaces/graduate_disciplines';
import { replaceTimeZone } from '../../../services/date';
import { useSelect } from '@refinedev/antd';

interface ClassroomCreateFormProps {
  formProps: FormProps;
  disciplineId?: string;
}

export const ClassroomCreateForm = ({
  formProps,
  disciplineId
}: ClassroomCreateFormProps) => {
  const [attendance, setAttendance] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (range: any) => {
    const [start, end] = range;

    formProps?.form?.setFieldsValue({
      starts_at: start ? replaceTimeZone(start) : '',
      ends_at: end ? replaceTimeZone(end) : ''
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDiscussionDateChange = (range: any) => {
    const [start, end] = range;

    formProps?.form?.setFieldsValue({
      discussion_starts_at: start ? replaceTimeZone(start) : '',
      discussion_ends_at: end ? replaceTimeZone(end) : ''
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleContentDateChange = (range: any) => {
    const [start, end] = range;

    formProps?.form?.setFieldsValue({
      content_access_starts_at: start ? replaceTimeZone(start) : '',
      content_access_ends_at: end ? replaceTimeZone(end) : ''
    });
  };

  const {
    selectProps: disciplineSelectProps,
    queryResult: disciplineQueryResult
  } = useSelect<IGraduateDiscipline>({
    resource: 'graduate_disciplines',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const props = disciplineId
    ? { ...formProps, initialValues: { discipline_id: disciplineId } }
    : formProps;

  const disciplines = useMemo(() => {
    return disciplineQueryResult?.data?.data.map(discipline => {
      const { name, id, external_reference } = discipline;

      const codExternal = external_reference ? `${external_reference} - ` : '';

      const label = `${codExternal} ${name}`;

      return {
        label,
        value: id
      };
    });
  }, [disciplineQueryResult]);

  const handleAttendance = useCallback(() => {
    formProps.form?.setFieldValue('minimum_attendance', null);
    setAttendance(!attendance);
  }, [attendance, formProps]);

  return (
    <Form {...props} layout="vertical">
      <Divider orientation="left" orientationMargin="0">
        Informações gerais da turma
      </Divider>
      <Form.Item
        label="Disciplina"
        name="discipline_id"
        hidden={!!disciplineId}
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select
          {...disciplineSelectProps}
          options={disciplines}
          style={{ minWidth: 200 }}
        />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col md={8}>
          <Form.Item
            label="Código da turma"
            name="code"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="N° de vagas"
            name="seats_total"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="Nota mínima (0-100)"
            name="passing_score"
            rules={[
              {
                required: false
              }
            ]}
          >
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Divider
        orientation="left"
        orientationMargin="0"
        className="required-mark"
      >
        Frequência de aulas ao vivo
      </Divider>
      <Space className="mb-4">
        <Switch onChange={handleAttendance} />
        <Typography.Paragraph className="!mb-0">
          Frequência obrigatória
        </Typography.Paragraph>
      </Space>
      {attendance && (
        <Col span={6}>
          <Form.Item
            label="Frequência mínima"
            name="minimum_attendance"
            rules={[
              {
                required: false
              }
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              style={{ width: '100%' }}
              placeholder="0 - 100"
            />
          </Form.Item>
        </Col>
      )}

      <Divider
        orientation="left"
        orientationMargin="0"
        className="required-mark"
      >
        Vigência
      </Divider>
      <Row gutter={[16, 16]}>
        <Form.Item name="starts_at" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="ends_at" hidden>
          <Input hidden />
        </Form.Item>
        <Col md={24}>
          <Form.Item
            name="dateRange"
            rules={[
              {
                required: true
              }
            ]}
          >
            <DatePicker.RangePicker
              className="w-full"
              showTime
              placeholder={[
                'início da turma (horário de Brasília)',
                'término da turma (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" orientationMargin="0">
        Acesso ao tira dúvidas
      </Divider>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <Form.Item name="discussion_starts_at" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="discussion_ends_at" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="dateRangeDiscussion">
            <DatePicker.RangePicker
              showTime
              className="w-full"
              placeholder={[
                'Data de início (horário de Brasília)',
                'Data de término (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
              onChange={handleDiscussionDateChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider
        orientation="left"
        orientationMargin="0"
        className="required-mark"
      >
        Acesso à visualização do conteúdo
      </Divider>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <Form.Item name="content_access_starts_at" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="content_access_ends_at" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="dateRangeContent"
            rules={[
              {
                required: true
              }
            ]}
          >
            <DatePicker.RangePicker
              showTime
              className="w-full"
              placeholder={[
                'Data de início (horário de Brasília)',
                'Data de término (horário de Brasília)'
              ]}
              format="DD/MM/YYYY HH:mm"
              onChange={handleContentDateChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" orientationMargin="0">
        Outras informações
      </Divider>
      <Form.Item label="Descrição" name="description">
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Habilitar inscrição automática na turma"
        name="auto_enroll"
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};
