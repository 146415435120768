import { AgentCreate, AgentShow, AgentEdit, AgentList } from 'pages/admin/agents';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'agents',
  label: 'Usuários',
  list: AgentList,
  edit: AgentEdit,
  create: AgentCreate,
  show: AgentShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'admin'
};

export default generateResources(resource);
