import AssetUpload from 'components/AssetUpload';
import LibraryContentUpload from 'components/LibraryContentUpload';
import { Form, Input, Modal, Select } from 'antd';
import { IVideo } from 'interfaces/videos';
import { useForm } from '@refinedev/antd';
import { useModalReturnType } from '@refinedev/core';
import { useParams } from 'react-router-dom';

interface CreateVideoModalProps {
  modal: useModalReturnType;
  onFinish?: () => void;
}

const CreateVideoModal = ({ modal, onFinish }: CreateVideoModalProps) => {
  const { id } = useParams();

  const onClose = () => {
    modal.close();
  };

  const { form, formProps } = useForm<IVideo>({
    resource: 'prometheus/v2/videos',
    onMutationSuccess: () => {
      modal.close();
      form.resetFields();
      onFinish?.();
    }
  });

  return (
    <Modal
      open={modal?.visible}
      title="Criar Vídeo"
      onCancel={onClose}
      onOk={form.submit}
    >
      <Form {...formProps} initialValues={{ library_id: id }} layout="vertical">
        <Form.Item name="library_id" hidden>
          <Input type="hidden" value={id} />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Nome é obrigatório' }]}
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item label="Thumbnail" name="thumbnail_url">
          <AssetUpload accept="image/*" type="thumbnail" />
        </Form.Item>
        <Form.Item label="Autores" name="authors">
          <Select mode="tags" />
        </Form.Item>
        <Form.Item
          label="Arquivo"
          name="source_url"
          rules={[{ required: true, message: 'Arquivo  é obrigatório' }]}
        >
          <LibraryContentUpload type="video" accept="video/*" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateVideoModal;
