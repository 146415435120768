import { academicIcon } from '../assets/icons/academic';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'academic',
  label: 'Gestão Acadêmica',
  icon: academicIcon
};

export default generateResources(resource);
