import { IAcademicRecord } from 'interfaces/academic_records';
import {
  IDisciplineModule,
  IGraduateDisciplineModule
} from 'interfaces/graduate_discipline_module';
import { IGraduateModule } from 'interfaces/graduate_courses';
import { getAPI } from 'requests/api';

type DisciplineWithAcademicRecord = IDisciplineModule & {
  academicRecordStatus?: IAcademicRecord['status'];
};

export interface CurriculumGridData extends IGraduateModule {
  disciplines: DisciplineWithAcademicRecord[];
}

export const fetchCurriculumGridData = async (
  courseId: string,
  enrollmentId: string
): Promise<CurriculumGridData[]> => {
  const [disciplinesModules, academicRecords] = await Promise.all([
    getAPI().get(
      `/admin/graduate/disciplines_modules?filter[by_course]=${courseId}`
    ),
    getAPI().get(
      `/admin/graduate/academic_records?filter[by_course_enrollment_id]=${enrollmentId}`
    )
  ]);

  return mountCurriculumGridData(
    disciplinesModules.data,
    academicRecords.data,
    courseId
  );
};

export const mountCurriculumGridData = (
  disciplinesModules: IGraduateDisciplineModule[],
  academicRecords: IAcademicRecord[],
  courseId: string
) => {
  if (disciplinesModules.length === 0) {
    return [];
  }

  const modules: CurriculumGridData[] = [];

  for (const dm of disciplinesModules) {
    if (dm.module.course_id !== courseId) {
      continue;
    }

    if (!modules.some(m => m.id === dm.module.id)) {
      modules.push({
        ...dm.module,
        disciplines: []
      });
    }
  }

  for (const dm of disciplinesModules) {
    const module = modules.find(m => m.id === dm.module.id);

    if (!module) {
      continue;
    }

    module.disciplines.push({
      ...dm.discipline,
      disciplineModuleKind: dm.kind,
      academicRecordStatus: academicRecords.find(
        ar => ar.discipline.id === dm.discipline.id
      )?.status
    });
  }

  return modules;
};
