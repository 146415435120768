import { TagEdit } from 'pages/epimetheus/tags';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'epimetheus/tags',
  label: 'Tags',
  edit: TagEdit,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
