import { Button, Col, Divider, Row, Space, Tag } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate } from 'services/date';
import { getAPI } from '../../../requests/api';
import { useDelete, useNotification, useShow } from '@refinedev/core';
import { useState } from 'react';

export const InteractiveClassShow = () => {
  const { queryResult } = useShow<IInteractiveClass>();
  const { mutateAsync: deleteInteractiveClass, isLoading: isDeleting } =
    useDelete<IInteractiveClass>();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);

  const result = queryResult.data?.data;

  if (!result) {
    return null;
  }

  const downloadReportFile = async () => {
    setLoading(true);

    try {
      const response = await getAPI().get(
        `/admin/interactive_classes/${result.id}/participants_report`,
        {
          responseType: 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio_participantes.csv');
      document.body.append(link);
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      if (error.status === 404) {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Não há relatório de participantes disponível.'
        });
      } else {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Erro ao baixar o relatório de participantes.'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'created': {
        return 'grey';
      }

      case 'started': {
        return 'green';
      }

      case 'finished': {
        return 'grey';
      }

      case 'cancelled': {
        return 'red';
      }

      default: {
        return 'default';
      }
    }
  };

  const translateStatus = (status: string) => {
    switch (status) {
      case 'created': {
        return 'Criado';
      }

      case 'started': {
        return 'Iniciado';
      }

      case 'finished': {
        return 'Finalizado';
      }

      case 'cancelled': {
        return 'Cancelado';
      }

      default: {
        return status;
      }
    }
  };

  return (
    <Show title="Visualizar aula" isLoading={queryResult.isLoading}>
      {!queryResult.isLoading && (
        <Space direction="vertical" size="large" className="w-full">
          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData label="ID da aula" value={result?.id} />
            </Col>
          </Row>

          {result?.start_url && (
            <Row gutter={[16, 24]}>
              <Col xs={24} md={24}>
                <ShowFieldData
                  label="Link do professor"
                  value={result?.start_url}
                />
              </Col>
            </Row>
          )}
          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData label="Título da aula" value={result?.title} />
            </Col>
          </Row>
          {result?.description && (
            <Row gutter={[16, 24]}>
              <Col xs={24} md={24}>
                <ShowFieldData label="Descrição" value={result?.description} />
              </Col>
            </Row>
          )}
          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData
                label="Data"
                value={formatDate(
                  result?.start_time,
                  'DD/MM/YYYY [às] HH:mm[h] ([GMT]Z)'
                )}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData
                label="Professores"
                value={result?.professors
                  .map(professor => `${professor.name} (${professor.email})`)
                  .join('\n')}
              />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData
                label="Turmas"
                value={result?.classrooms
                  .map(
                    classroom =>
                      `${classroom.discipline.name} - ${classroom.code}`
                  )
                  .join(', ')}
              />
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData
                label="Status"
                value={
                  <Tag color={getStatusColor(result?.status)}>
                    {translateStatus(result?.status)}
                  </Tag>
                }
              />
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            <Col xs={24} md={24}>
              <ShowFieldData
                label="Controle de Frequência"
                value={
                  <Button
                    icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                    onClick={downloadReportFile}
                    disabled={result.status !== 'finished' || loading}
                  >
                    Baixar dados da aula realizada
                  </Button>
                }
              />
            </Col>
          </Row>
          {result?.status !== 'cancelled' && (
            <>
              <Divider />

              <Button
                danger
                onClick={() =>
                  deleteInteractiveClass({
                    id: result?.id,
                    resource: 'interactive_classes'
                  })
                }
                loading={isDeleting}
              >
                Cancelar aula
              </Button>
            </>
          )}
        </Space>
      )}
    </Show>
  );
};
